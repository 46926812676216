import Swal from 'sweetalert2';
import SessionData from "../models/session.class";
import m from "mithril";
import Database from "../models/database.class";
import 'datalist-css';
import $ from 'jquery';

import Config from '../config';
import Utils from '../models/utils.class';
import alertify from 'alertifyjs';




let continueGame = new Event("continueGame");
let modalAlert;
class Modals {


    static confirmVideos () {
       
        return new Promise((resolve) => {
            if (modalAlert != undefined) modalAlert.close().destroy();
            
            let introtext = Database.getUIJSON().videocheckPreText;
            let checklist = "<ul class='errorcheck'>";
            checklist += "</ul>";

            modalAlert = alertify.confirm( Database.getUIJSON().videocheckHeadline, introtext+checklist, 
                (evt, value) => { resolve(true); }
                , () => { resolve(false) })
            .setting({
                'closable': false,
                'closableByDimmer': false,
                'movable': false
            })  
            .set('labels', {ok: Database.getUIJSON().videocheckYes, cancel: Database.getUIJSON().videocheckNo}); 
            $('.ajs-footer').css("display", "none");    
                
            setTimeout(() => {
                let browserclass = (SessionData.errorcheck.browser) ? '' : 'checked';
                $('.errorcheck').append ("<li class='"+ browserclass + "'> browser </li>");
            }, 1500); 
            setTimeout(() => {
                let deviceclass = (SessionData.errorcheck.device) ? '' : 'checked';
                $('.errorcheck').append ("<li class='"+ deviceclass + "'> Webcam </li>");
            }, 1600);    
            setTimeout(() => {
                let audioclass = (SessionData.errorcheck.audio) ? '' : 'checked';
                $('.errorcheck').append ("<li class='"+ audioclass + "'> audio </li>");
            }, 1700);  
            setTimeout(() => {
                let videoclass = (SessionData.errorcheck.video) ? '' : 'checked';
                $('.errorcheck').append ("<li class='"+ videoclass + "'> video </li>");
            }, 1800);
            setTimeout(() => {
                let socketclass = (SessionData.errorcheck.socket) ? '' : 'checked';
                $('.errorcheck').append ("<li class='"+ socketclass + "'> socket </li>");

                $('.ajs-footer').fadeIn();

                // if any error, write generic info
                if (SessionData.errorcheck.socket || SessionData.errorcheck.video || SessionData.errorcheck.audio || SessionData.errorcheck.device || SessionData.errorcheck.browser) {
                    $('.ajs-content').append (Database.getUIJSON().videocheckFailPostText);
                } else {
                    $('.ajs-content').append (Database.getUIJSON().videocheckSuccessPostText);
                }
            }, 1900);    
 
            
                
             
 
           
             
        })
    }


    static addCardTitle () {
       
        return new Promise((resolve) => {
            if (modalAlert != undefined) modalAlert.close().destroy();
            // it takes the info of the previous modal from setcontent
            modalAlert = alertify.prompt( Database.getUIJSON().modal_add_title, Database.getUIJSON().modal_add_title_description, ''
               , (evt, value) => { resolve(value); }
               , () => { console.log ("cancelled") })
               
               //.setContent("")
               .set('labels', {ok: Database.getUIJSON().modal_confirm, cancel: Database.getUIJSON().modal_deny}); 
        })
    }

    
    static addGroupTitle(activities, atmospheres, rooms, group) {
        if (modalAlert != undefined) modalAlert.close().destroy();
        
    //    console.log (group);
        let currenttitle = "";
        group._objects.forEach(function (el, i) {
            if (el.cardtype == "grouptitle") {
                currenttitle = el.text;
            }
        })
        
        let titles = currenttitle.split(" - ");
        
        
        let atmospheres_data = "";
        for (let j = 0; j < atmospheres.length; j++) {
            atmospheres_data += "<option>" + atmospheres[j].title + "</option>";
        }
        
        let atmosphere_title = (titles[0] != undefined) ? titles[0] : "";
        let atmospheres_html = `<div class="atmospheresdata datainput"><i class="bi bi-triangle-fill"></i><input list="atmospheres" id="atmospheres-choice" name="atmospheres-choice" value="` + atmosphere_title + `" />
        <datalist id="atmospheres">` 
        + 
        atmospheres_data
        +  `</datalist></div>`;



        let activities_data = "";
        for (let i = 0; i < activities.length; i++) {
            activities_data += "<option>" + activities[i].title + "</option>";
        }
        let activity_title = (titles[1] != undefined) ? titles[1] : "";
        let activities_html = `<div class="activitiesdata datainput"><i class="bi bi-hexagon-fill"></i><input list="activities" id="activities-choice" name="activities-choice"  value="` + activity_title + `" autocomplete="off" />
        <datalist id="activities">` 
        + 
        activities_data
        +  `</datalist></div>`;
        
 

 


        let rooms_data = "";
        for (let k = 0; k < rooms.length; k++) {
            rooms_data += "<option>" + rooms[k].title + "</option>";
        }
        let def_room_title = (rooms[0] != undefined) ? rooms[0].title : "";
        let room_title = (titles[2] != undefined) ? titles[2] : def_room_title;
        let rooms_html = `<div class="roomsdata datainput"><i class="bi bi-square-fill"></i><input list="rooms" id="rooms-choice" name="rooms-choice" value="` + room_title + `" />
        <datalist id="rooms">` 
        + 
        rooms_data
        +  `</datalist></div>`;



        // style the datalist (show arrow, selected one by default)
        let content = atmospheres_html + activities_html + rooms_html;
        return new Promise((resolve) => {
            
            modalAlert = alertify.prompt( Database.getUIJSON().modal_add_title, Database.getUIJSON().modal_add_title_description, ''
               , (evt, value) => { 
                   // get results from datalists
                   let v = {
                       activitytitle: $('#activities-choice').val(),
                       atmospheretitle: $('#atmospheres-choice').val(),
                       roomtitle: $('#rooms-choice').val()
                   }
                   resolve(v); 
                }, 
                () => { console.log ("cancelled") })
               //
               .setContent(content)
               .set('labels', {ok: Database.getUIJSON().modal_confirm, cancel: Database.getUIJSON().modal_deny});

        });

        
        
        
    }

    // in this modal, the user has to enter the roomname and their username to join a session
    static roomselection() {
        Swal.mixin({
            input: 'text',
            allowOutsideClick: false,
            confirmButtonText: Database.getUIJSON().modal_next_btn + ' &rarr;',
            progressSteps: ['1', '2']
        }).queue([
            // user should enter the room name
            {
                title: Database.getUIJSON().modal_room_header,
                text: Database.getUIJSON().modal_room_description,
                preConfirm: (v) => {
                    if (v == "") {
                        Swal.showValidationMessage(Database.getUIJSON().modal_empty_warning);
                    }
                }
            },

            // user should enter their username
            {
                title: Database.getUIJSON().modal_user_header,
                text: Database.getUIJSON().modal_user_description,
                preConfirm: (v) => {
                    if (v == "") {
                        Swal.showValidationMessage(Database.getUIJSON().modal_empty_warning);
                    }
                }
            },

            // when successfully entered
        ]).then((result) => {
            if (result.value) {
                Utils.joinRoom(result.value[0], result.value[1]);
            }
        })

    }


    static simpleNotification (msg) {
        alertify.dismissAll();

        alertify.set('notifier','position', 'top-center');
        alertify.notify(msg);
    }
    static errorNotification (msg) {
        alertify.dismissAll();
        alertify.set('notifier','position', 'top-center');
        alertify.error(msg,6); 
    }
    static persistentNotification (msg) {
        alertify.dismissAll();
        alertify.set('notifier','position', 'top-center');
        alertify.error(msg, 0); 
    }

    // @socketid: who clicked the button
    static confirmstart() {
        let userready = false;
        let stepkey = "step"+SessionData.currentGameStep; // at which step are we of the gameloop
        
        Swal.fire({
            position: "bottom-end",
            toast: true,
           // icon: "question",
            timer: Config.gameTimer,
            timerProgressBar: true,
           // title: Database.getGameStepsJSON().steps[stepkey].modalinfo,
            confirmButtonText: Database.getGameStepsJSON().steps[stepkey].modalinfo,
            showConfirmButton: true,
            target: '#gamestep-content',
            confirmButtonAriaLabel: Database.getGameStepsJSON().steps[stepkey].modalinfo,
            customClass: { 
                container: 'position-absolute'
              },
            preConfirm: (v) => {
                return new Promise((resolve) => {

                    userready = !userready;
                    
                    if (userready) {
                        Swal.update({confirmButtonText: Database.getUIJSON().modal_deny});
                       // console.log ("emit that I'm ready");
                        let data = {
                            username: SessionData.username,
                            room: SessionData.roomname
                        }
                        SessionData.socket.emit("startnextstep", data);
                        Swal.resumeTimer();
                    } else {
                        Swal.update({confirmButtonText: Database.getUIJSON().modal_confirm});
                        let data = {
                            username: SessionData.username,
                            room: SessionData.roomname
                        }
                        SessionData.socket.emit("pausenextstep", data);
                        
                        Swal.stopTimer(); // if player unconfirms pause the timer
                    }

                    Swal.enableButtons();
                    
                })

            },
            willClose: () => {
                Swal.close();
                // show the next step of the loop after a timeout, to make sure SWAL is completely gone
                setTimeout(() => {
                    document.dispatchEvent(continueGame); // let the gameloop know that we can continue to the next round
                }, 100);
                
            },
        })
        
        Swal.stopTimer();

        
        if (SessionData.currentGameStep == Config.stepForModerator) {
            Swal.disableButtons(); // disable buttons for everyone if it's the moment to choose a moderator
        }
        

        if (SessionData.currentGameStep > Config.stepForModerator) {
            if (SessionData.socketID != SessionData.moderator) Swal.disableButtons(); // disable button controls for everyone but the moderator
            // if players have chosen a moderator, enable the button only for the moderator
            $(".videocontainer").removeClass("moderator");
            $( ".videocontainer" ).removeClass("hover");
            $(".videocontainer_"+SessionData.moderator).addClass("moderator");
        }
        
        
    }

}

export default Modals;