import m from "mithril";
import Config from "../config";
import SessionData from "../models/session.class";
import Utils from "../models/utils.class";
import Database from "../models/database.class";


class Splash {
    constructor() {
        
        Database.loadUIJSON(); // load just the UI
  
        
        document.addEventListener('UIDBLoaded', () => {
            Utils.showModal("roomselection");
        });
    }

    view() {

        
    }
}


export default Splash;