import $ from 'jquery';
import Config from '../config';
import SessionData from './session.class';
import Utils from './utils.class';
class boardLayout {
    
    
    
    // static repositionRooms (group) {
    //     let roomcounter = 0;
    //     // rearrange elements in group after removing an element
    //     for (var  i = 0; i < group._objects.length; i++) {
    //         let obj = group._objects[i];
    //         if (obj.cardtype == Config.cardtypes.room) {
    //             obj.left = boardLayout.roomsPositions()[roomcounter].left - obj.width;
    //             obj.top = boardLayout.roomsPositions()[roomcounter].top + obj.height;

    //             roomcounter++;
    //         }
    //     }
    //     group.addWithUpdate();
    //     group.setCoords();
    //     SessionData.canvas.renderAll();
    // }

    // static repositionAtmospheres (group) {
    //     let atmospherecounter = 0;
    //     let activitiescounter = 0;
        
    //     // if the group has 2 activities or less, move atmospheres accordingly
    //     for (var  i = 0; i < group._objects.length; i++) {
    //         if (group._objects[i].cardtype == Config.cardtypes.activity) activitiescounter++;
    //     }
    //     let dist;
    //     if (activitiescounter <= 2) dist = 30;
        
    //     // rearrange elements in group after removing an element
    //     for (var  i = 0; i < group._objects.length; i++) {
    //         let obj = group._objects[i];
    //         if (obj.cardtype == Config.cardtypes.atmosphere) {
    //             if (atmospherecounter > 2) {
    //                 console.log ("more than 2 atmospheres");
    //                 return;
    //             }
    //             let l = boardLayout.atmospheresPositions()[atmospherecounter].left;
    //             let t = boardLayout.atmospheresPositions()[atmospherecounter].top;
    //             obj.left = (activitiescounter <= 2) ? l - group.width/2 - obj.width/2 : l - group.width/2 - obj.width/2;
    //             obj.top = (activitiescounter <= 2) ? t - group.height/2 : t - group.height/2 + 25;

    //             atmospherecounter++;
    //         }
    //     }
    //     group.addWithUpdate();
    //     group.setCoords();
    //     SessionData.canvas.renderAll();
    // }


    static repositionCards (group) {
 
        let activitycounter = -1;
        let atmospherescounter = 0;
        let roomscounter = 0;

        let titlecard; 
        // rearrange elements in group after removing an element
        for (var  i = 0; i < group._objects.length; i++) {
            let obj = group._objects[i];
            
            
            if (obj.cardtype == Config.cardtypes.activity) {
                if (activitycounter == -1) {
                    obj.left = 0;
                    obj.top = 0;
                }else {
                    obj.left = boardLayout.activitiesPositions()[activitycounter].left;
                    obj.top = boardLayout.activitiesPositions()[activitycounter].top;
                }
                
                activitycounter++;
            }

            if (obj.cardtype == Config.cardtypes.atmosphere) {
                obj.left = boardLayout.atmospheresPositions()[atmospherescounter].left;
                obj.top = boardLayout.atmospheresPositions()[atmospherescounter].top;
                atmospherescounter++;
            }


            if (obj.cardtype == Config.cardtypes.room) {
                obj.left = boardLayout.roomsPositions()[roomscounter].left;
                obj.top = boardLayout.roomsPositions()[roomscounter].top;
                roomscounter++;
            }

            // group.addWithUpdate();
            // group.setCoords();
            if (obj.cardtype == Config.cardtypes.city) {
                group.setCoords();
                group.addWithUpdate();
                obj.left = group.width/2;
                obj.top = group.height/2;
                
                //obj.left = ((activitycounter+1)*75);
                //console.log (obj.left);
            //     obj.top = (activitycounter+1)*87;
            }
            
            if (obj.cardtype == "grouptitle") {
                titlecard = obj;
            }
 

            if (obj.cardtype == Config.cardtypes.opencircle || obj.cardtype == Config.cardtypes.safecircle) {
                group.setCoords();
                let r; // circle radius
                let t; // top position of circle
                let l; // left position of circle
                
                if (group.width >= group.height) {
                    
                    r = group.width / 2;
                    t = -(r/2);
                    l = 0;
                } else {
                    
                    r = group.height / 2;
                    t = 0;
                    l = - r + group.width/2;
                }

                
                obj.left = l;
                obj.top = t;
            }
        }

        // don't jump group when detaching.
        let ox = group.left;
        let oy = group.top;
        
        group.addWithUpdate();
        group.setCoords();
        
        SessionData.canvas.renderAll();

        if (titlecard != undefined) {
            
       
            
            switch (activitycounter) {
                case 0 : case 1:
                    
                    titlecard.top = -50;
                    titlecard.left = 0;

                break;
                case 2: case 3: case 4: case 9: case 10: case 11: case 16: case 17: case 18: case 23: case 24: case 25:
                    titlecard.top = -90;
                    titlecard.left = 0;

                break;
                case 5 : case 6: case 7: case 8: case 12: case 13: case 14: case 15: case 19: case 20: case 21: case 22: case 26: case 27: case 28: case 29:
                    titlecard.top = -130;
                    titlecard.left = 0;
             
                break;
            }
            group.addWithUpdate();
            group.setCoords();
            SessionData.canvas.renderAll();
           
        }

        group.left = ox;
        group.top = oy;
        SessionData.canvas.renderAll();
        

    }


    
  
    static activitiesPositions () {
        // height of activity is 87
        // width of activity is 75
        return [
// ROW1            
            // first postion is 0,0
            
            {left:0, top:87}, // 2nd activity in group
// ROW2
            {left:75, top:-43},
            {left:75, top:43},
            {left:75, top:130},
// ROW3
            {left:150, top:-87},
            {left:150, top:0},
            {left:150, top:87},
            {left:150, top:174},

// ROW4
            {left:225, top:-43},
            {left:225, top:43},
            {left:225, top:130},

// ROW5
            {left:300, top:-87},
            {left:300, top:0},
            {left:300, top:87},
            {left:300, top:174},

// ROW6            
            {left:375, top:-43},
            {left:375, top:43},
            {left:375, top:130},

// ROW 7         
            {left:450, top:-87},
            {left:450, top:0},
            {left:450, top:87},
            {left:450, top:174}, 

// ROW 8     
            {left:525, top:-43},
            {left:525, top:43},
            {left:525, top:130},    
// ROW 9         
            {left:600, top:-87},
            {left:600, top:0},
            {left:600, top:87},
            {left:600, top:174},             
        ]
    }


    static atmospheresPositions() {
        return [
            {left:-25, top:43},
            {left:-25, top:130},
            {left:25, top:-43},
        ]
    }

    static roomsPositions() {
        return [
            {left:25, top:174}
        ]
    }

 
    static snapCards (movingobject, targetobject) {
        if (movingobject == undefined) return;
        if (targetobject == undefined) return;
        if (movingobject.cardtype == undefined) return;

        
        let cardsInGroup = Utils.cardsInGroup(targetobject);
        

        //console.log (this.atmospheresPositions());
        
        if (movingobject.cardtype == Config.cardtypes.activity) {
            if (cardsInGroup.initPosition != undefined && this.activitiesPositions()[cardsInGroup.activities-1] != undefined) {
                movingobject.left = cardsInGroup.initPosition.left + this.activitiesPositions()[cardsInGroup.activities-1].left;
                movingobject.top = cardsInGroup.initPosition.top + this.activitiesPositions()[cardsInGroup.activities-1].top;
            } 
            
        }
        if (movingobject.cardtype == Config.cardtypes.atmosphere) {
            if (cardsInGroup.initPosition != undefined && this.atmospheresPositions()[cardsInGroup.atmospheres] != undefined) {
                movingobject.left = cardsInGroup.initPosition.left + this.atmospheresPositions()[cardsInGroup.atmospheres].left;
                movingobject.top = cardsInGroup.initPosition.top + this.atmospheresPositions()[cardsInGroup.atmospheres].top;
            }
            
        }
        if (movingobject.cardtype == Config.cardtypes.room) {
            if (cardsInGroup.initPosition != undefined && this.roomsPositions()[cardsInGroup.rooms] != undefined) {
                movingobject.left = cardsInGroup.initPosition.left + this.roomsPositions()[cardsInGroup.rooms].left;
                movingobject.top = cardsInGroup.initPosition.top + this.roomsPositions()[cardsInGroup.rooms].top;
            }
            
        }


    }

 

    static positionDeleteBtn(obj) {
        
        setTimeout(() => {
            SessionData.canvas.renderAll();
            SessionData.canvas.calcOffset();
            var absCoords = {
                left: obj.oCoords.tl.x,
                top: obj.oCoords.tl.y
            };
            $('.deletebtn').css("left", absCoords.left + 'px');
            $('.deletebtn').css("top", absCoords.top + 'px'); 
            if (SessionData.canvas.getActiveObject() != undefined) {
                if (SessionData.canvas.getActiveObject().cardtype != Config.cardtypes.linecontroller && SessionData.canvas.getActiveObject().cardtype != Config.cardtypes.ankommen) $('.deletebtn').removeClass("hide");
            }   
        }, 250);
        
        
    }

    static positionGroupBar(obj) {
        // the position of the group bar should be dynamic, to make sure it fits in the window
        let groupbarwidth = $(".grouptools").children().length * 50;
        
        let bw = window.innerWidth;
       

        let mindistX = bw - groupbarwidth;
        let posx;

        let mindistY = 50;
        let posy;
        
        if (obj.oCoords.tl.x > mindistX) {
            posx = mindistX - 50
        } else {
            posx = obj.oCoords.tl.x;
        }

        if (obj.oCoords.tl.y < mindistY) {
            posy = mindistY;
        } else {
            posy = obj.oCoords.tl.y - 50;
        }

        SessionData.canvas.renderAll();
        SessionData.canvas.calcOffset();
        var absCoords = {
            left: posx,
            top: posy
        };


        $('.grouptools').css("left", absCoords.left + 'px');
        $('.grouptools').css("top", absCoords.top + 'px');
    }


    static initToolbarLayoutVersion2() {
        let element = $("#stack-menu");

        element.addClass('stack-menu-v1');
        element.addClass('tabbed');


        element.find('ul:first').addClass('parent');

        let children = $("#stack-menu ul.parent > li").addClass("children");
        let subchildren = children.find("ul:first").addClass("subchildren");
        subchildren.find("ul").addClass("subsubchildren");

        let tab = $(".tab");

        $(document).mouseup(e => {
            if (!element.is(e.target) && element.has(e.target).length === 0) { // if the target of the click isn't the container ... nor a descendant of the container
                children.removeClass('selected');
                element.removeClass("selected");
            }
        });


        children.find("ul.subchildren li .subsubchildren:first").addClass("show");
        children.find("ul.subchildren li a:first").addClass("active");


        // clicking the menu container should open it
        element.click(function (e) {
            e.preventDefault();
            element.addClass("selected");
        })
        // clicking any main item should open its children
        children.click(function (e) {
            e.preventDefault();
            children.not($(this)).removeClass("selected");
            $(this).addClass("selected");
        });

        tab.click(function (e) {
            e.preventDefault();
            $(".tab").removeClass("active");
            $(this).addClass("active");

            $(".subsubchildren").removeClass("show");
            $(this).siblings(".subsubchildren").addClass("show");
        })
    }
    static initToolbarLayoutVersion1() {
        let element = $("#stack-menu");

        element.addClass('stack-menu-v1');

        //element.find('ul').addClass('children');
        element.find('ul:first').addClass('parent');
        //element.find('ul:first').children('li:not(:has(ul))').addClass("parent");
        let children = $("#stack-menu ul.parent > li").addClass("children");
        let subchildren = children.find("ul:first").addClass("subchildren");
        subchildren.find("ul").addClass("subsubchildren");

        // clicking outside of the menu, should close the menu 
        $(document).mouseup(e => {
            if ($(e.target).is('img')) return;
//            if (SessionData.currentGameStep == Config.stepForActivitiesContainer) return;
            if (!element.is(e.target) && element.has(e.target).length === 0) { // // if the target of the click isn't the container...nor a descendant of the container
                children.removeClass('selected');
                element.removeClass("selected");
            }
        });

        // clicking the menu container should open it
        element.click(function (e) {
            e.preventDefault();
            element.addClass("selected");
        })
        // clicking any main item should open its children
        children.click(function (e) {
            e.preventDefault();
            children.not($(this)).removeClass("selected");
            $(this).addClass("selected");
        });
    }
}

export default boardLayout;