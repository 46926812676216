import io from 'socket.io-client';
import Config from '../config';
import SessionData from './session.class';
import Utils from './utils.class';
import Swal from 'sweetalert2';
import Database from './database.class';
import Modals from '../views/modals.class';
import toolbarHelper from './toolbarHelper.class';
import $ from 'jquery';
import m from 'mithril';
class Socket {

    constructor() {
        SessionData.socket = io(Config.server, Config.serverOptions);

        this.reconnecttriggered = false;
                
        this.initRequest();
        this.initListeners();
    }


    initRequest () {
        SessionData.socket.request = function request(type, data = {}) {
            return new Promise((resolve, reject) => {
                SessionData.socket.emit(type, data, (data) => {
                if (data.error) {
                  reject(data.error)
                } else {
                  resolve(data)
                }
              })
            })
          }
    }


    initListeners() {
        
        SessionData.socket.on('log', function (array) {
            console.log.apply(console, array);
        });

        // SessionData.socket.on ('connect', () => {
        //     console.log (" + CONNECT + ", SessionData.socket);
        // })

        SessionData.socket.on("userleft", (d) => {
            console.log ("+++ LEFT", d);
        })
        SessionData.socket.on ('connect_error',  (d) => {
            console.log (" + CONNECT ERROR + ", d);
            //Modals.errorNotification("connect_error: " + d );
            SessionData.errorcheck.socket = true;
        })
        SessionData.socket.on ('connect_timeout', (d) => {
            console.log (" + CONNECT TIMEOUT + ", d);
            //Modals.errorNotification("connect_timeout: " + d );
        })
        SessionData.socket.on ('error', (d) => {
            console.log (" + ERROR + ", d);
            this.reconnect();
        })
        SessionData.socket.on ('disconnect', (d) => {
            // the socket might disconnect due to e.g. ping timeout. We should handle that case by reconnecting to the room
            console.log (" + DISCONNECT + ", d);     
        })
        SessionData.socket.on ('disconnecting', (d) => {
            console.log (" + DISCONNECTING + ", d);
        })
        SessionData.socket.on ('newListener', (d) => {
            console.log (" + NEW LISTENER + ", d);
        })
        
        SessionData.socket.on ('reconnect', (d) => {
            console.log (" + RECONNECT + ", d);
        })
        SessionData.socket.on ('reconnect_attempt', (d) => {
            console.log (" + RECONNECT ATTEMPT + ", d);
        })
        SessionData.socket.on ('reconnecting', (d) => {
            console.log (" + RECONNECTING + ", d);
        })
        SessionData.socket.on ('reconnect_error', (d) =>{
            console.log (" + RECONNECT ERROR + ", d);
        })
        
        // SessionData.socket.on ('ping', (d) => {
        //     console.log (" + PING + ", d);
        // })
        // SessionData.socket.on ('pong', (d) =>{
        //     console.log (" + PONG + ", d);
        // })
        
        SessionData.socket.on ('reconnect_failed', (d) => {
            console.log (" + RECONNECT FAILED + ", d);
        })
        SessionData.socket.on ("removePeer", (data) => {
            if ( ! Config.videocall) {
                console.log ("REMOVE HERE THE USER", data);
                $('#videocontainer_'+data).remove();
                
                SessionData.totalUsers = SessionData.totalUsers-1;
                
                
                console.log ("%c user leaving with socket: " + data, 'background: red; color: #000');
                // remove user cursor when they leave the game
                let cursorEl = document.getElementById("cursor-" + data);
                if (cursorEl != null) cursorEl.remove();
                
                
                if (SessionData.moderator == data) {
                    SessionData.moderator = "";
                    Modals.persistentNotification(Database.getUIJSON().moderator_left_game);
                    Config.stepForModerator = SessionData.currentGameStep; // allow to set a moderator for this step
                }
            }
            
        });
        SessionData.socket.on ("setmoderator", (data) => {
            if (data.moderator == "" && SessionData.currentGameStep >= Config.stepForModerator) {
                Config.stepForModerator = SessionData.currentGameStep; // allow to set a moderator for this step
                Swal.disableButtons();
                return;
            } 

            if (data.moderator == "") return;

            let hadmoderator = false;
            if (SessionData.moderator != "") hadmoderator = true;
            
            Utils.setModerator(data.moderator);
            let index = SessionData.users.findIndex((obj => obj.user_id == data.moderator)); // get the user object we should edit
            // SessionData.users[index].moderator = true; // set selected moderator to true
            if (index == undefined) return;

            let txt_data = {username: SessionData.users[index].user_name}
            
           let msg = Utils.applyTemplate(Database.getUIJSON().set_moderator_notification, txt_data);
       //    console.log (SessionData.users[index].user_name, hadmoderator);
           if (SessionData.users[index].user_name != undefined && !hadmoderator) Modals.simpleNotification(msg);
        })
        // when a user joins the room, update their canvas to the latest version
        SessionData.socket.on('join', (data) => {
            console.log ("NEW USER JOINED", data);
            Utils.postCanvasToUsers();

            // let txt_data = {username: data.user_name};
            // Modals.simpleNotification(Utils.applyTemplate(Database.getUIJSON().user_joined_notification, txt_data));            

            if (!Config.videocall) {
                let userjoined = {
                    producer_id: "nomedia",
                    producer_name: data.user_name,
                    producer_socket_id: data.user_id
                }
                let d = {
                    user_id: data.user_id,
                    user_name: data.user_name
                }
        
                SessionData.users.push(d);
                console.log ("NO VIDEO CALL");
                Utils.createNoStreamInfo(userjoined, false, false);
            }
        })

        // show user mouse
        SessionData.socket.on('moving', (data) => {
            Utils.getCursorElement(data);
        });

        //  late players should start from currentstep of others
        SessionData.socket.on("updatecanvas", (data) => {
            
// CREATE USER CONTAINER            
            // add already existing users
            if (!Config.videocall) {         
                let d = {
                    user_id: data.user_id,
                    user_name: data.user_name
                }
                let userjoined = {
                    producer_id: "nomedia",
                    producer_name: data.user_name,
                    producer_socket_id: data.user_id
                }
                SessionData.users.push(d);
                Utils.createNoStreamInfo(userjoined, false, false);
            }
            // don't update game session if we're already in
             if (SessionData.currentGameStep == data.gamestep) return;

// UPDATE GAME SESSION
            SessionData.currentGameStep = data.gamestep; // update user to the other player's gamestep
            

// UPDATE BTN TEXT
            let stepkey = "step" + SessionData.currentGameStep; // at which step are we of the gameloop
            if (stepkey <= 19) Swal.update({confirmButtonText: Database.getGameStepsJSON().steps[stepkey].modalinfo});



            if ( SessionData.currentGameStep >= Config.stepForActivitiesContainer) {
                $("#gameWrapper .window").addClass( "forceleft");
                $("#gameWrapper .window").removeClass( "center"); 
            }
            Utils.updateTotalProgressbar();
            SessionData.activitiesonboard = data.activitiesOnBoard; // update the number of activities currently on the board
            
            if (SessionData.currentGameStep == Config.stepForActivitiesContainer) {
                // open activities
                $('.stack-menu-v1').addClass("selected");
                $('#search_0').focus();
                $("."+Config.cardtypes.activity+'.children').addClass("selected");
                $('#activitiesbox').removeClass("hide"); // show the warenkorb
                if (window.innerWidth < 1400) {
                    $('#uservideos').fadeOut();
                    $('#minimizedvideobar').fadeIn();
                    $('#gameWrapper .window').addClass("extreme");
                }
            } 
            
// UPDATE TOOLBAR
            // if you join later in the game, the toolbar doesn't get unlocked
            Object.keys(Database.getGameStepsJSON().steps).forEach( (step, i) => {   

                if (i <= SessionData.currentGameStep) {
                    let unlocktype = Database.getGameStepsJSON().steps["step" + i].unlocks;
                    if (unlocktype != undefined && unlocktype != "") {
                        $('.' + unlocktype).removeClass("hide");
                    }
                    
                }
            });

            
// UPDATE EVALUATION
        //     update data, if we're in evaluation
            let evaluation = Database.getGameStepsJSON().steps["step" + SessionData.currentGameStep].evaluation;
            if (evaluation != undefined) SessionData.evaluation = true;
            if (evaluation != undefined) SessionData.evaluationTargetIsland = evaluation.islandTarget;
            
            // load canvas from other players and update the objectsOnCanvas array
            // SessionData.canvas.clear();
            // SessionData.objectsOnCanvas = [];

// UPDATE CANVAS
            SessionData.canvas.loadFromJSON(data.json, () => {
                console.log ("%c CANVAS UPDATED", 'background: #FFF; color: #000', data.json);
                SessionData.canvas.getObjects().map((o) => {
                    
                    if (o.cardtype == Config.cardtypes.activity) $('#'+o.id).addClass("used-card");
                        
                    SessionData.objectsOnCanvas.push(o);
                    if (o.cardtype == Config.cardtypes.island) toolbarHelper.groupMouseEvents(o);
                });
                SessionData.canvas.renderAll();

// UPDATE TIMER
                setTimeout(() => {
                    let elem = document.getElementById("currentprogress" + SessionData.currentGameStep);
                    if (elem != undefined) elem.style.height = data.currenttime;    
                }, 1000);
                
            });

        })





        SessionData.socket.on('startnextstep', (data) => {
            let txt_data = { username: data.username};
            // update the timer
            let stepkey = "step" + SessionData.currentGameStep; // at which step are we of the gameloop
            Swal.resumeTimer();
            Swal.update({title: Utils.applyTemplate(Database.getGameStepsJSON().steps[stepkey].modalinfo, txt_data)});
            Swal.update({showConfirmButton:false});
        });





        SessionData.socket.on('pausenextstep', (data) => {

            //update the timer
            let stepkey = "step" + SessionData.currentGameStep; // at which step are we of the gameloop
            Swal.stopTimer();
            Swal.update({title: Database.getGameStepsJSON().steps[stepkey].modalinfo});
        });
        

    }



}
export default Socket;