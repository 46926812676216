import m from 'mithril';
import RoomClient from "./RoomClient.class";
import SessionData from './session.class';
import Socket from './socket.class';
import Board from '../models/board.class';
import GameLoop from '../models/gameloop.class';
import Toolbar from '../models/toolbar.class';
import $ from "jquery";
import Utils from './utils.class';
import modal from '../views/modals.class';


import getStats from 'getstats';
import Database from './database.class';


let socket;

class mediasoup {

    constructor() {
        this.init();
    }

    init() {
        let s = new Socket();
        socket = SessionData.socket;
      
        socket.on("connect", () => {
            SessionData.socketID = socket.id;
            if (!SessionData.connected) m.mount(document.getElementById("canvasWrapper"), Board);
            if (!SessionData.connected) m.mount(document.getElementById("toolbarWrapper"), Toolbar);
            if (!SessionData.connected) m.mount(document.getElementById("gameWrapper"), GameLoop);

            $('#gameWrapper').fadeOut();
            $('#canvasWrapper').fadeOut();
            $('#toolbarWrapper').fadeOut();
            SessionData.errorcheck.socket = false;
            modal.confirmVideos().then((v) => {
              $('#gameWrapper').fadeIn();
              $('#canvasWrapper').fadeIn();
              $('#toolbarWrapper').fadeIn();
              if (!v) {
                SessionData.roomClient.pauseProducer(SessionData.roomClient.mediaType.audio);
                $('.audio').addClass("slash"); 
                $('.fa-microphone').addClass('fa-microphone-slash');
                $('.fa-microphone').removeClass('fa-microphone');
                $('a.button.audio').attr("tooltip", Database.getUIJSON().myaudioon);
                $('a.button.video').attr("tooltip", Database.getUIJSON().myvideoon);
                $('a.item.videotoggle').attr("tooltip", Database.getUIJSON().myvideoon);
                $('a.item.audiotoggle').attr("tooltip", Database.getUIJSON().myaudioon);

                $('a.button.audio').attr("aria-label", Database.getUIJSON().myaudioon);
                $('a.button.video').attr("aria-label", Database.getUIJSON().myvideoon);
                $('a.item.videotoggle').attr("aria-label", Database.getUIJSON().myvideoon);
                $('a.item.audiotoggle').attr("aria-label", Database.getUIJSON().myaudioon);

                SessionData.roomClient.pauseProducer(SessionData.roomClient.mediaType.video);
                $('.video').addClass("slash"); 
                $('.fa-video').addClass('fa-video-slash');
                $('.fa-video').removeClass('fa-video');


                // m.mount(document.getElementById("canvasWrapper"), Board);
                // m.mount(document.getElementById("toolbarWrapper"), Toolbar);
                // m.mount(document.getElementById("gameWrapper"), GameLoop);
              }
            });
            // mount stuff only if they haven't been mounted yet. Valid during reconnect
            
            this.joinRoom(SessionData.username, SessionData.roomname);

            SessionData.connected = true;
        });
    }

 

    
    view() {

        

        return [
          

          // CLOSE BUTTON
          m("a", {
            class: "close videoclose active", "aria-label": Database.getUIJSON().closeVideosTxt,tooltip: Database.getUIJSON().closeVideosTxt, distX:50, distY:0,
            onclick: function () {
              $(this).toggleClass("active");
              if ($(this).hasClass("active")) {
                  $('#uservideos').fadeIn();
                  $('#minimizedvideobar').fadeOut();
                  $('#gameWrapper .window').removeClass("extreme"); 
              } else {
                $('#uservideos').fadeOut();
                $('#minimizedvideobar').fadeIn();
                $('#gameWrapper .window').addClass("extreme");
              }
            }
          }),



          // MINIMIZED BAR
          m("div", {id:"minimizedvideobar"}, [
            // TITLE
            m("div", {class:"item title"}, "Videos"),
           
            // AUDIOTOGGLE
            m("a", {class:"item audiotoggle", "aria-label": Database.getUIJSON().myaudiooff, tooltip: Database.getUIJSON().myaudiooff, distX:0, distY:25,
             onclick: () => {
              
              if (!SessionData.roomClient.pauseAudio){
                $('.audio').addClass("slash");
                $('.fa-microphone').addClass('fa-microphone-slash');
                $('.fa-microphone').removeClass('fa-microphone');
                SessionData.roomClient.pauseProducer(SessionData.roomClient.mediaType.audio);
                $('a.item.audiotoggle').attr("tooltip", Database.getUIJSON().myaudioon);
                $('a.item.audiotoggle').attr("aria-label", Database.getUIJSON().myaudioon);
              } else {
                $('.audio').removeClass("slash");
                $('.fa-microphone-slash').addClass('fa-microphone');
                $('.fa-microphone-slash').removeClass('fa-microphone-slash');
                SessionData.roomClient.resumeProducer(SessionData.roomClient.mediaType.audio);
                $('a.item.audiotoggle').attr("tooltip", Database.getUIJSON().myaudiooff);
                $('a.item.audiotoggle').attr("aria-label", Database.getUIJSON().myaudiooff);
              }
            }}, [
              m("i", {class: "fas fa-microphone"})
            ]),
           

            // VIDEOTOGGLE
            m("a", {class:"item videotoggle", "aria-label":Database.getUIJSON().myvideooff,tooltip: Database.getUIJSON().myvideooff, distX:0, distY:25,
            onclick: () => { 
              if (!SessionData.roomClient.pauseVideo){
                $('.video').addClass("slash"); 
                $('.fa-video').addClass('fa-video-slash');
                $('.fa-video').removeClass('fa-video');
                $('a.item.videotoggle').attr("tooltip", Database.getUIJSON().myvideoon);
                $('a.item.videotoggle').attr("aria-label", Database.getUIJSON().myvideoon);
                SessionData.roomClient.pauseProducer(SessionData.roomClient.mediaType.video);
              } else {
                $('.video').removeClass("slash"); 
                $('.fa-video-slash').addClass('fa-video');
                $('.fa-video-slash').removeClass('fa-video-slash');
                $('a.item.videotoggle').attr("tooltip", Database.getUIJSON().myvideooff);
                $('a.item.videotoggle').attr("aria-label", Database.getUIJSON().myvideooff);
                SessionData.roomClient.resumeProducer(SessionData.roomClient.mediaType.video);
              }
            }}, [ 
              m("i", {class: "fas fa-video"})
            ]),
            
            // CLOSE BAR
            m("a", {class:"close active", "aria-label":Database.getUIJSON().openVideosTxt, tooltip: Database.getUIJSON().openVideosTxt, distX:50, distY:0,
             onclick:() => {
              $('#uservideos').fadeIn();
              $('.videoclose').addClass("active");
              $('#minimizedvideobar').fadeOut();
              $('#gameWrapper .window').removeClass("extreme"); 

            }})
          ]),
          
          
          // ALL USER VIDEOS
          m("section", {id: "uservideos"}) // end section
        ]
      }



    joinRoom(name, room_id) {
        if (SessionData.roomClient && SessionData.roomClient.isOpen()) {
            console.log("already in a room");
        } else {     
          SessionData.roomClient = new RoomClient(uservideos, window.mediasoupClient, SessionData.socket, room_id, name, this.roomOpen)
          this.addListeners();
            
        }
       // console.log("JOIN ROOM", name, room_id);
    }


    roomOpen() {
      //  console.log("ROOM OPENED", SessionData.roomClient);   
    }


    addListeners() {
       

        SessionData.roomClient.on(RoomClient.EVENTS.stopAudio, () => {
            console.log ("STOP AUDIO");

        })
        SessionData.roomClient.on(RoomClient.EVENTS.startAudio, () => {
     //       console.log ("START AUDIO");
        })
        SessionData.roomClient.on(RoomClient.EVENTS.enterRoom, () => {
            
        });
        SessionData.roomClient.on(RoomClient.EVENTS.startVideo, () => {
      //      console.log ("START VIDEO");

            // // a DEBUG way to get the stats per user
          //   let _pc = SessionData.roomClient.producerTransport._handler._pc;

          //   getStats(_pc, function(result) {
          //     // console.log (d.username + " bandwidth: "+ Utils.formatBytes (result.bandwidth.speed) );
          //      if(result.connectionType.remote.candidateType.indexOf('relayed') !== -1) {
          //        result.connectionType.remote.candidateType = 'TURN';
          //      }
          //      else {
          //          result.connectionType.remote.candidateType = 'STUN';
          //      }
          //      console.log (result);
          //      console.log ("bandwidth: " + Utils.formatBytes (result.bandwidth.speed));
          //      //$('.bandwidth_'+d.socketID).html( "("+ Utils.formatBytes (result.bandwidth.speed) + ")");
          //     //console.log (d.username + " candidate: "+ result.connectionType.remote.candidateType );
       
          //  }, 2000);
        })
        SessionData.roomClient.on(RoomClient.EVENTS.stopVideo, () => {
            console.log ("STOP VIDEO");
        })
        SessionData.roomClient.on(RoomClient.EVENTS.exitRoom, () => {
            console.log ("EXIT ROOM");
        })

    }
}

export default mediasoup;