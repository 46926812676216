import m from "mithril";
import Utils from "./utils.class";
import SessionData from "../models/session.class";
import Database from "./database.class";
import $ from "jquery";
import Config from "../config";

let totalGameSteps, gameStepTitles = [], sound;

class GameLoop {



    constructor() {
        totalGameSteps = Object.keys(Database.getGameStepsJSON().steps).length - 1; // how many gamesteps do we have? (we start counting from zero)
        // create an array with the step titles
        Object.keys(Database.getGameStepsJSON().steps).forEach(step => {
            gameStepTitles.push(Database.getGameStepsJSON().steps[step].title);
            SessionData.totalDuration += Database.getGameStepsJSON().steps[step].duration_inminutes;
        });

    }

    oncreate() {
        this.playGameStep(SessionData.currentGameStep);
        this.playersReadyListener();
        this.windowResizeListener();
        window.addEventListener("resize", this.windowResizeListener);
    }



    disableImageDragging() {
        let imgs, i; // used in local loop
      
        // collect all images on the page
        imgs = document.getElementsByTagName('img');
        // loop through fetched images
        for (i = 0; i < imgs.length; i++) {
            // and define onmousedown event handler
            imgs[i].onmousedown = function (e) {
                e.preventDefault();
            };
        }
    }

    onupdate() {
        // play progress bar of individual step
         //console.log ("GLUPDATE");
         
        if (SessionData.currentGameStep > Object.keys(Database.getGameStepsJSON().steps).length - 1) {
            // game is over 
            return;
        }

        let step_duration = Database.getGameStepsJSON().steps["step" + SessionData.currentGameStep].duration_inminutes * 60 * 1000;
        if (step_duration != 0) {
            Utils.initProgressbar("stepprogress" + SessionData.currentGameStep, step_duration, false);
        } else {
            Utils.resetProgressbar();
        }
        this.disableImageDragging(); 
    } 



    // when all players have pressed ready, an event is dispatched to load the next loop
    playersReadyListener() {
        document.addEventListener('continueGame', (e) => {
        
            SessionData.currentGameStep += 1;

            if (SessionData.currentGameStep > totalGameSteps) {
                console.log("%c FINISH: " + SessionData.currentGameStep + " / " + totalGameSteps, 'background: #FF0000; color: #000');
                this.finishGame();
            } else {
                console.log("%c step: " + SessionData.currentGameStep + " / " + totalGameSteps + " (" + Config.version + ")", 'background: #00FF00; color: #000');
                this.playGameStep(SessionData.currentGameStep);
                Utils.updateTotalProgressbar();
            }
            m.redraw();
        })

    }


    playGameStep(i) {
        let unlocktype = Database.getGameStepsJSON().steps["step" + i].unlocks;

        Utils.clearHighlights();
        let evaluation = Database.getGameStepsJSON().steps["step" + i].evaluation;
        if (evaluation != undefined) {
            SessionData.evaluation = true;
            SessionData.evaluationTargetIsland = evaluation.islandTarget;
            SessionData.canvas.discardActiveObject().renderAll(); // deselect selection if we're in evaluation mode
            SessionData.objectsOnCanvas.forEach(function(obj) {
                obj.lockMovementX = true;
                obj.lockMovementY = true;
            });
        }
        
        if (evaluation == undefined) {
            SessionData.evaluation = false;
            SessionData.evaluationTargetIsland = false;
            SessionData.objectsOnCanvas.forEach(function(obj) {
                if (obj.cardtype != Config.cardtypes.line) obj.lockMovementX = false;
                if (obj.cardtype != Config.cardtypes.line) obj.lockMovementY = false;
            });
        }
        
        if ( i >= Config.stepForActivitiesContainer) {
            $("#gameWrapper .window").addClass( "forceleft"); // move window to the left if we're at this step
            $("#gameWrapper .window").removeClass( "center"); 
        }
        if (i == Config.stepForActivitiesContainer) {
            // open activities
            $('.stack-menu-v1').addClass("selected");
           
            $("."+Config.cardtypes.activity+'.children').addClass("selected");
            $('#activitiesbox').removeClass("hide"); // show the warenkorb
            if (window.innerWidth < 1400) {
                $('#activitiesbox').addClass("large");
                $('#uservideos').fadeOut();
                $('#minimizedvideobar').fadeIn();
                $('#gameWrapper .window').addClass("extreme");
            }
            
            setTimeout(() => {
                $('#search_0').trigger('focus');    
            }, 500);
            
        } 


        if (i <= 2) {
            $('.window-controls .close').css("display", "none");
        } else {
            $('.window-controls .close').css("display", "block");
        }
        if (i >= Config.stepToallowGroup) {
            $('a#groups').fadeIn(); // enable accessibility
        }

        // if (i == 2) {
        //     Utils.analyseBoard();
        // } else {
        //     $('#venn').remove();
        //     $('#gameWrapper .window').removeClass("large");
        // }

        if (i > Config.stepForActivitiesContainer) $('#activitiesbox').addClass("hide"); // show the warenkorb

       
        

        if (unlocktype != "") {
            $('.' + unlocktype).removeClass("hide");
            // open the menu if we're unlocking one of the main categories
            if (unlocktype == Config.cardtypes.atmosphere || unlocktype == Config.cardtypes.room) {
                $('.stack-menu-v1').addClass("selected");
                $("."+unlocktype+'.children').addClass("selected");
                if (unlocktype == Config.cardtypes.atmosphere) $('#search_1').trigger('focus');
                if (unlocktype == Config.cardtypes.room) $('#search_2').trigger('focus');
            }

            // the moderator, should drop the ankommen in the field
            if (unlocktype == Config.cardtypes.ankommen) {
                if (SessionData.moderator == SessionData.socketID) $('li.Ankommen a')[0].click();
            }
            


        }
        let tj = Database.getGameStepsJSON().steps["step" + i].timejoker;
        if (tj != 0) SessionData.timeJoker = tj;

        // all players need to confirm before starting a session. This makes sure that the session starts when everyone is in the room.
        if (i == 1) {
            let duration = SessionData.totalDuration * 60 * 1000;
            Utils.initTotalProgressbar("totalprogress", duration);
        }

        Utils.showModal("confirmstart");

    }

    // finish game loop. Download canvas, etc.
    finishGame() {
        Utils.analyseBoard();
        Utils.saveCanvasJSON();
    }


    windowResizeListener () {
        let containerWidth = window.innerWidth;
        if (containerWidth < 1400) {
        //    $('.videoclose').removeClass("active");
            $('#gameWrapper .window').addClass("extreme");
            $('#activitiesbox').addClass("large");
        } else {
        //    $('.videoclose').addClass("active");
            $('#gameWrapper .window').removeClass("extreme");
            $('#activitiesbox').removeClass("large");
        }
        if ($('.videoclose').hasClass("active")) {
            $('#uservideos').fadeIn();
            $('#minimizedvideobar').fadeOut();
        } else {
          $('#uservideos').fadeOut();
          $('#minimizedvideobar').fadeIn();
        }
    }


    view() {
        let steptitle, stepdescription;

        if (SessionData.currentGameStep <= totalGameSteps) {
            steptitle = Database.getGameStepsJSON().steps["step" + SessionData.currentGameStep].title;
            // add template for step6, nr. of activities you can add
            let limitActivitiesPerUser = Math.floor(Config.activitiesAllowed / SessionData.totalUsers);
            let txt_data = {activitiesnr: limitActivitiesPerUser};
            
        
            stepdescription = Utils.applyTemplate(Database.getGameStepsJSON().steps["step" + SessionData.currentGameStep].description, txt_data);
            //stepdescription = Database.getGameStepsJSON().steps["step" + SessionData.currentGameStep].description;
        }

        let content = [{
            title: steptitle,
            description: stepdescription
            
        }]


        return m("section", {
            id: "gameinfo"
        }, [
            // total game progress bar
            m("div", {
                class: "progressbar total"
            }, [
                m("div", {
                    class: "inner",
                    id: "totalprogress"
                }),
                m("div", {
                    id: "totalprogressbar-text"
                })
            ]),

            // minimizable window for steps
            m("div", {
                class: "window center"
            }, [
                
                
                
                m("nav", {
                    class: "window-controls"
                }, [
                    m("a", {
                        class: "close", "aria-label":Database.getUIJSON().closeWindowTxt, tooltip: Database.getUIJSON().closeWindowTxt, distX:50, distY:0,
                        onclick: function (e) {
                            e.redraw = false;
                            $('.window').toggleClass('minimized');
                            if ( $('.window').hasClass('minimized')) {
                                $(this).attr("tooltip", Database.getUIJSON().openWindowTxt);
                                $(this).attr("aria-label", Database.getUIJSON().openWindowTxt);
                            } else {
                                $(this).attr("tooltip", Database.getUIJSON().closeWindowTxt);
                                $(this).attr("aria-label", Database.getUIJSON().closeWindowTxt);
                            }
                            
                        }
                    })
                ]),



                m("aside", {
                    class: "progress-sidebar"
                }, [
                    gameStepTitles.map(function (title, index) {
                        var _class = "content-block";
                        if (SessionData.currentGameStep == index) _class = _class + " active";
                        let _title = (index <= SessionData.currentGameStep) ? title : ""; // show only the title of the current gamestep, not the next ones
                        _class += (index <= SessionData.currentGameStep) ? "" : " hidden";

                        if (index > SessionData.currentGameStep) {
                            return;
                        } else {
                            return m("div", {
                                class: _class
                            }, [
                                // m("div", {
                                //     class: "step-title"
                                // }, _title),
                                // m("div", {
                                //     class: "step-index"
                                // }, index),
                                m("div", {
                                    class: "progressbar"
                                }, [
                                    m("div", {
                                        class: "stepprogress inner",
                                        id: "stepprogress" + index
                                    }, [m("div", {class:"currentprogress", id:"currentprogress"+index})]),
                                    m("div", {class:"remainingtime", tooltip: Database.getUIJSON().remainingtimeTxt, distX:0, distY:-50}, "")
                                ])
                            ])
                        }

                    })
                ]),





                m("section", {
                    class: "window-content",
                    id: "gamestep-content"
                }, [



                    content.map(function (d, index) {
                        return m("div", {
                            class: "content"
                        }, [
                            m("div", {
                                class: "title"
                            }, d.title),
                            m("div", {
                                class: "description"
                            }, m.trust(d.description)),

                            // m("audio", {
                            //     class: "player " + d.showplayer,
                            //     autoplay: 1,
                            //     controls: 1
                            // }, [
                            //     m("source", {
                            //         source: d.mp3,
                            //         type:"audio/mpeg"
                            //     })
                            // ])
                        ])
                    })
                ])
            ])
        ])
    }

}

export default GameLoop;