const Config = {
    version: "1.5", 
    serverOptions: {
        reconnection: true
    },
    server: "https://hochdreiserver.kulturstiftung-bund.de/", // http://localhost:3016 || https://hochdreiserver.changemod.com
    metadataOnCanvasUpdate: ['cardtype', "title", "evented", 'selectable', 'id', 'originIsland', 
                            'line', 'lineTo', 'lineFrom', 'linecontroller', 'interval',
                            'fill', 'hasControls', "hasBorders", "subTargetCheck", "tags", "perPixelTargetFind",
                            "hoverCursor", "lockMovementX", "lockMovementY", "analysis"], // when you reconnect and the canvas gets pulled from the socket, make sure these metadata are sent too

    videocodecoptions: {
        videoGoogleStartBitrate: 1000
    },
    videoencodings: [{ 
        rid: 'r0',
        maxBitrate: 100000,
        //scaleResolutionDownBy: 10.0,
        scalabilityMode: 'S3T3'
        },
        {
        rid: 'r1',
        maxBitrate: 300000,
        scalabilityMode: 'S3T3'
        },
        {
        rid: 'r2',
        maxBitrate: 900000,
        scalabilityMode: 'S3T3'
    }],
    videoconstraints: {
        video: {
            width: { ideal:150 },
            //height: { min: 120, ideal: 240, max:360 },
            //height: { min: 400, ideal: 1080 },
            //aspectRatio: { ideal: 1.7777777778 },
            frameRate: { ideal: 20 },
           // facingMode: { exact: "user" }
        }, 
        audio: false
    },
    audioconstraints: {
        audio: {
            autoGainControl: true,
            channelCount: {ideal:1},
            echoCancellation: true,
            latency: {ideal:0.01},
            noiseSuppression: true,
            sampleRate: {ideal:24000},
            sampleSize: {ideal:8},
        },
        video: false 
    },
    cardtypes: { // the different cardtypes. Their ids are defined in the respective json files
        activity: "Aktivitäten",
        atmosphere: "Atmosphären",
        room: "Räume",
        ankommen: "Ankommen", 
        island: "island",
        safecircle: "geschützt", // the loudness circle
        opencircle: "durchlässig", // the loudness circle
        city: "city", // the "stadtsatelliten" card
        linecontroller: "linecontroller", // the dot to be able to manipulate a line
        line: "line" // the connecting line
    },    
    stepToallowGroup: 9, // from this step on, grouping is allowed
    activityTileSize: 60, // the width of an activity tile
    videocall: true, // toggle this flag to test with/without videocall
    stepToSaveData: 20, // at which step should we download the canvas and save the json
    stepForActivitiesContainer: 6, // at which step the activities container (warenkorb) should be activated
    stepForModerator: 2, // at which step, players should choose an administrator
    activitiesAllowed: 30, // the maximum amount of activities on the canvas
    atmospheresPerGroup: 3, // the maximum amount of atmosphere cards you can attach to a group
    roomsPerGroup: 1, // the maximum amount of room cards you can attach to a group
    gameTimer: 1000, // how long we wait for a modal to automatically close 
    cardimagesfolder: "/images/cardimages/", // where are all the images saved
    mp3folder: "mp3/" // where are all the mp3 files are saved
}

export default Config; 