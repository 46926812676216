import m from "mithril";
//import VideoConference from '../models/videoconference.class';
import mediasoup from "../models/mediasoup.class";
import Database from '../models/database.class';
import SessionData from "../models/session.class";
import Utils from "../models/utils.class";
import $ from 'jquery';
import Config from "../config";


class Main {
    
    constructor() {
        // the splash route already loads the UI, so don't load it again if coming from there
        if (Object.keys(Database.getUIJSON()).length === 0) {
            Database.loadUIJSON(); 
            document.addEventListener('UIDBLoaded', (e) =>{
                this.init();
            });
        } else {
            Database.loadDatabase();
       
        }

        if (SessionData.username == "" || SessionData.roomname == "" ) return; // make sure we don't add more listeners until everything is ready
        this.initGameListeners();
    }

    onupdate () {
        if (m.route.param().version == "cheat") {
            this.initCheats();
        }
    }
    initCheats () {
       // Config.gameTimer = 1000; // shorter waiting period
       // Config.activitiesAllowed = 1000; // basically limitless activities
        $('.grouptools').removeClass("hide");
        $('.grouptoolbar').removeClass("hide");
        $('#stack-menu .children').removeClass("hide");
    }


    init() {
        if (m.route.param().username != undefined) SessionData.username = m.route.param().username;
        if (m.route.param().username != undefined) SessionData.roomname = m.route.param().roomname;
        // make sure we have a valid roomname and username
        if (SessionData.roomname == "" || SessionData.username == "") {
            // show a popup, where the user can enter username & roomname
            Utils.showModal("roomselection");
            document.removeEventListener("UIDBLoaded", this.init); // remove the listener, because we'll reroute here once input correct
            return;
        } else {
            Utils.joinRoom(SessionData.roomname, SessionData.username);
            document.removeEventListener("UIDBLoaded", this.init); // remove the listener, because we'll reroute here once input correct
            return;
        }
        
    }


    initGameListeners() {
        document.addEventListener('cardsLoaded', function (e) {
        //    console.log("cards", Database.getCardsJSON());
        })
        document.addEventListener('gamestepsLoaded', function (e) {
        //    console.log("gamesteps", Database.getGameStepsJSON());
        })
        
        document.addEventListener('ToolsDBLoaded', function (e) {
         //   console.log("main toolbar", Database.getMainToolsJSON());            
         //   console.log("group toolbar", Database.getGroupToolsJSON());            
        })
        document.addEventListener('UIDBLoaded', function (e) {
         //   console.log ("ui loaded from splash", Database.getUIJSON());         
        })

        
        document.addEventListener('DBloadComplete', function (e) {
            m.mount(document.getElementById("videosWrapper"), mediasoup);
            console.log("%c VERSION "+ Config.version, 'background: #FF00FF; color: #000');
        })

        window.onpopstate = history.onpushstate = function(e) { 
         //   console.log ("history change");
            window.location.reload();
         }


        

         
    }

    view() {

     


       // let info = Config.DEBUG ? m("pre", {class: "info"}, d) : "";

        return m("section", {
            id: "main"
        }, [
        //    info,
            m("section", {
                id: "canvasWrapper"
            }),
            m("section", {
                id: "videosWrapper"
            }),
            m("section", {
                id: "gameWrapper"
            }),
            m("section", {
                id: "toolbarWrapper"
            })
        ])

    }
}


export default Main;