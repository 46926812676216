import $ from 'jquery';
import m from "mithril";
import Config from '../config';
import SessionData from "./session.class";
import Database from "./database.class";
import Utils from './utils.class';
import modal from './../views/modals.class';
import toolbarHelper from './toolbarHelper.class';

import boardLayout from './boardLayout.class';

let level0 = [];
let level1 = [];
let level2 = [];
let maintools = [];
let grouptools = [];
let _this;

let activitiesToDrop = [];
class Toolbar {


    constructor() {
        _this = this;
        this.parseDatabase();
        this.socketListeners();
        this.initAutomaticGrouping(); 
    }


 
    initAutomaticGrouping (){
        // FUNCTION TO CREATE A GROUP ON DRAG
        SessionData.canvas.on('object:moved', (e) => {
            
            if (SessionData.currentGameStep < Config.stepToallowGroup) return; // don't allow grouping before this step
            
            e.target.setCoords();
            
            // if we're draggging a group, don't intersect with anything. Show the toolbar
            if (e.target.cardtype == Config.cardtypes.island) {
            //    console.log ("REMOVE HIDE", e);
                $(".grouptools").removeClass("hide");
                $('.deletebtn').addClass("hide");
                boardLayout.positionGroupBar(e.target);
                return;
            } else { 
                $(".grouptools").addClass("hide");
                boardLayout.positionDeleteBtn(e.target); 
            }


            let groupvalidity;  
            let targets = 0;    
            let targetObj;
           
            // 3. if object intersects with group, add it to group
            SessionData.objectsOnCanvas.forEach(function(obj) {
                if (obj === e.target) return;  
                // 1. check if creating a group is allowed
                if (e.target.intersectsWithObject(obj)) {
                    groupvalidity = toolbarHelper.checkGroupValidity(e.target, obj);
                    if (groupvalidity == 0 ) return; 
                    if (obj.type != "circle" && obj.type != "line") {
                        targets += 1;
                        targetObj = obj;   
                        //console.log ("target", targetObj);     
                    }
                    
                }
            });  

            
            // make sure we're dropping a card on one object
            if (targets == 1) {
               if ( targetObj.cardtype == Config.cardtypes.linecontroller && e.target.cardtype != Config.cardtypes.island) return;
               if ( e.target.cardtype == Config.cardtypes.linecontroller && targetObj.cardtype != Config.cardtypes.island) return; // don't group line controllers

                // 2. if two objects intersect, create a group
                if (targetObj.cardtype != Config.cardtypes.island && e.target.intersectsWithObject(targetObj) && targetObj.id != undefined ) {
                    let groupID = "island_"+Date.now();
                    toolbarHelper.createGroupFromObjects(e.target, targetObj, groupID); 
                   
                    SessionData.socket.emit("createGroupFromObjects", {
                        objectID: e.target.id,
                        targetID: targetObj.id,
                        groupID: groupID,
                        room: SessionData.roomname
                    });
                }
                // 3. if object intersects with group, add it to group
                if (targetObj.cardtype == Config.cardtypes.island && e.target.intersectsWithObject(targetObj) && targetObj.id != undefined ) {
                    toolbarHelper.addToGroup(e.target, targetObj); 
                    SessionData.socket.emit("addToGroup", {
                        objectID: e.target.id,
                        targetID: targetObj.id,
                        room: SessionData.roomname
                    });  
                }

                

                // 4. connect line to target group

                if (targetObj.cardtype == Config.cardtypes.island && e.target.cardtype == Config.cardtypes.linecontroller) {
                    toolbarHelper.connectLineToGroup(e.target, targetObj);
                    SessionData.socket.emit("connectLineToGroup", {
                        movingObject: e.target.id,
                        targetObject: targetObj.id,
                        room: SessionData.roomname
                    });

                }

                targetObj.set("opacity", 1);

            } else {
                
                if (targets > 1) console.log ("YOU'RE TARGETING MORE THAN ONE OBJECT");
            }

        });
        


        // FUNCTION TO HIGHLIGHT WHICH OBJECTS WILL MERGE
        SessionData.canvas.on('object:moving', (e) => {
            if (SessionData.currentGameStep < Config.stepToallowGroup) return; // don't allow grouping before this step
           // don't highlight following objects
           if (e.target.cardtype == Config.cardtypes.island) return; 
           if (e.target.cardtype == Config.cardtypes.line) return; 
           if (e.target.cardtype == Config.cardtypes.ankommen) return; 
          
           

           e.target.setCoords();
            
            SessionData.canvas.forEachObject(function(obj) {
                if (obj === e.target) return;  
            
                if (e.target.intersectsWithObject(obj)) {
                    if (e.target.cardtype == Config.cardtypes.activity && obj.cardtype == Config.cardtypes.activity) obj.set('opacity', .5); // if we bring an activity on top of another activity
                    if (e.target.cardtype == Config.cardtypes.linecontroller && obj.cardtype == Config.cardtypes.island) obj.set('opacity', .5); // if we bring a linecontroller on top of an island
                    if (obj.cardtype == Config.cardtypes.island) obj.set('opacity', .5); // if we bring any card to an island

                    if ( e.target.cardtype != Config.cardtypes.activity  && obj.cardtype == Config.cardtypes.activity) obj.set('opacity', 1); // if we move any object (not an activity) on top of an activity
                    if (obj.cardtype != Config.cardtypes.activity && obj.cardtype != Config.cardtypes.island) obj.set('opacity', 1); // if we move any object (not an activity) on top of an island


                    
                    
                } else {
                    obj.set('opacity', 1)
                }
                
            });   
        });
    } 



    
     
     dropActivities () {
        for (let i = 0; i < activitiesToDrop.length; i++) {
             let analysis = (activitiesToDrop[i].analysis != undefined) ? activitiesToDrop[i].analysis : "";
            let card = _this.makeCard(activitiesToDrop[i].title, activitiesToDrop[i].cardtype, activitiesToDrop[i].id, activitiesToDrop[i].image, analysis).then( (r) => {
          
                if (activitiesToDrop[i] != undefined && activitiesToDrop[i].id != undefined) $('#wk-'+activitiesToDrop[i].id).remove(); 

               
                SessionData.socket.emit("objectadded", {
                    title: activitiesToDrop[i].title,
                    id: activitiesToDrop[i].id,
                    cardtype: activitiesToDrop[i].cardtype,
                    image: activitiesToDrop[i].image,
                    analysis: analysis,
                    left: card.left,
                    top: card.top,
                    scaleX: card.scaleX,
                    scaleY: card.scaleY,
                    angle: card.angle,
                    username: SessionData.Name,
                    room: SessionData.roomname
                });

                if (i >= activitiesToDrop.length-1) {
                    activitiesToDrop = []; // empty the array of activities to drop once all are dropped
                    m.redraw();
                }
            });
        }


        
    }


    socketListeners() {

        SessionData.socket.on("activitysaved", function(data) {
            $('#' + data.id).addClass('used-card');
            m.redraw();
        })
        SessionData.socket.on("activityunsaved", function(data) { 
            $('#' + data.id).removeClass("used-card");
            m.redraw();
        })
        SessionData.socket.on("createGroupFromObjects", function (data) {
            let obj = Utils.getObjectById(data.objectID);
            let target = Utils.getObjectById(data.targetID);
            toolbarHelper.createGroupFromObjects(obj, target, data.groupID);
        });
        SessionData.socket.on("addToGroup", function (data) {
            let obj = Utils.getObjectById(data.objectID);
            let group = Utils.getObjectById(data.targetID);
            toolbarHelper.addToGroup(obj, group);
        });
        SessionData.socket.on("connectLineToGroup", function (data) {
            let movingObject = Utils.getObjectById(data.movingObject);
            let targetObject = Utils.getObjectById(data.targetObject);
            toolbarHelper.connectLineToGroup(movingObject, targetObject);
        });
        SessionData.socket.on("detachFromGroup", function (data) {
            // get the object and its parent group from the data
            let obj;
            if (data.groupID == undefined) return;
            let target = Utils.getObjectById(data.groupID);
            if (target == undefined || target._objects == undefined) return;
            for (let i = 0; i < target._objects.length; i++) {
                if (data.objectID == target._objects[i].id) obj = target._objects[i];
            }

            if (obj != undefined) toolbarHelper.detachFromGroup(obj, target);
        });


        SessionData.socket.on("createCityConnection", function (data) {
  //          console.log("RECEIVED CITY CONNECTION");
            let group = Utils.getObjectById(data.groupID);

            // rename city tile
            let cityexists = false;
            group._objects.forEach(function (el, i) {
                if (el.cardtype == Config.cardtypes.city) {
                    el._objects[1].set('text', data.title);
                    SessionData.canvas.renderAll();
                    cityexists = true;
                }
            })
            if (cityexists) return;


            toolbarHelper.createImage(Config.cardimagesfolder + "Stadtsatellit.png").then((oimg) => {
                oimg.scaleToWidth(50);

                let text = new fabric.Text(data.title, {
                    originX: 'center',
                    originY: 'center',
                    fontSize: 9,
                    fontFamily: 'Karla',
                    left: 0.5 * 50,
                    top: 55
                })
                let g = new fabric.Group([oimg, text], {
                    left: group.left + group.width - 25,
                    top: group.top + group.height,
                    originX: 'left',
                    originY: 'top',
                    centeredRotation: true,
                    hasControls: false
                });
                g.cardtype = Config.cardtypes.city;
                g.id = data.id;

                group.addWithUpdate(g);
                group.setCoords();
                SessionData.canvas.renderAll();
            });
        });
        SessionData.socket.on("createcircle", function (data) {

            
            let group = Utils.getObjectById(data.groupID);
            toolbarHelper.createCircle(data.type, data.stroke, data.fill, group);

        })

        SessionData.socket.on("createConnection", function (data) {
            let pointer = data.pointer;
            let group = Utils.getObjectById(data.groupID);
            let points = [group.left + group.width/2, group.top + group.height/2, pointer.x, pointer.y];

            // make a line next to the group and the circle controller
            let line = _this.makeLine(points);
            line.id = data.lineID;
            
            let controller1 = _this.makeLineController(pointer.x, pointer.y, line, group);
            controller1.id = data.controllerID; // assign the controllerID and lineID of other user
            line.linecontroller = data.controllerID;
   

            SessionData.objectsOnCanvas.push(line);
            SessionData.objectsOnCanvas.push(controller1);
            SessionData.canvas.add(line);
            SessionData.canvas.add(controller1);


            SessionData.canvas.bringToFront(line);
            SessionData.canvas.bringToFront(controller1);
            // group.line connect groups done
            
            if (group.lineFrom != undefined) group.lineFrom.push(line.id); // assign the line to the group, so we can manipulate it when we move the group
            if (group.lineFrom == undefined) group.lineFrom = [line.id];

            SessionData.canvas.renderAll();
        })

        SessionData.socket.on("createGroupTitle", function (data) {
            let group = Utils.getObjectById(data.groupID);
            // rename group tile
            let titleexists = false;
            group._objects.forEach(function (el, i) {
                if (el.cardtype == "grouptitle") {
                    el.set('text', data.title);
                    SessionData.canvas.renderAll();
                    titleexists = true;
                }
            })
            if (titleexists) return;

            let text = new fabric.Text(data.title, {
                originX: 'left',
                originY: 'top',
                fontFamily: 'Karla',
                fontSize: 14,
                backgroundColor: "#000",
                fill: "#FFF",
                top: group.top - 15
            })
            text.left = group.left + group.width/2 - text.width/2;
            text.cardtype = "grouptitle";
            text.id = "grouptitle_" + Date.now();
            SessionData.objectsOnCanvas.push(text);

            group.addWithUpdate(text);
            group.setCoords();

            SessionData.canvas.renderAll();


        })
        SessionData.socket.on('objectadded', function (data) {
            // hide toolbar item
            $('#' + data.id).addClass("used-card");
            if (data.cardtype == Config.cardtypes.activity) SessionData.activitiesonboard +=1;
            
            _this.makeCard(data.title, data.cardtype, data.id, data.image, data.analysis);
        });


        SessionData.socket.on('updateProgressbarDuration', function (data) {
             Utils.updateProgressbarDuration(data.extratime);
            toolbarHelper.disableTimeJoker();
        })
        SessionData.socket.on('objectremoved', function (data) {
            
            let obj = Utils.getObjectById(data.id);
            if (obj == undefined) return;
            //console.log("remove card with id", obj.id, " of the type ", obj.cardtype);
            // show toolbar item
            $('#' + data.id).removeClass("used-card");


           // SessionData.canvas.remove(obj);
            Utils.removeObjectById(obj.id);            
          
            if (obj.cardtype == Config.cardtypes.line) {
                Utils.removeObjectById(data.linecontroller);
            }
            if (obj.cardtype == Config.cardtypes.activity) {
                SessionData.activitiesonboard -= 1; // reduce the number of total activities on board
            }

        });
    }


    oncreate() {
        //this.initToolbarLayout();
        
        if (m.route.param().version == 2) {
            boardLayout.initToolbarLayoutVersion2();
        } else {
            boardLayout.initToolbarLayoutVersion1();
        }
        
    }

    onupdate() {
        let i = SessionData.currentGameStep;
        if (SessionData.currentGameStep == Config.stepForModerator) this.initActivitiesContainer ();

        if ( SessionData.currentGameStep > Object.keys(Database.getGameStepsJSON().steps).length - 1) {
            // game is over 
            return;
        }
        if (Database.getGameStepsJSON().steps["step" + i].timejoker > 0) {
            toolbarHelper.enableTimeJoker();
        }
    }


    parseDatabase() {
        level0 = Object.keys(Database.getCardsJSON());
        let mt = Object.keys(Database.getMainToolsJSON());
        
        // put the maintools in an array
        mt.forEach(function (el, i) {
            Database.getMainToolsJSON()[mt[i]].cardtype = Database.getMainToolsJSON()[mt[i]].title;
            Database.getMainToolsJSON()[mt[i]].id = "mt_" + Database.getMainToolsJSON()[mt[i]].title;
            maintools.push(["", Database.getMainToolsJSON()[mt[i]]]); // the array should be structured like the cards array to use the same initCard function
        })


        let gt = Object.keys(Database.getGroupToolsJSON());
        // put the group in an array

        gt.forEach(function (el, i) {
            //Database.getGroupToolsJSON()[gt[i]].cardtype = Database.getGroupToolsJSON()[gt[i]].title;
          //  Database.getGroupToolsJSON()[gt[i]].id = "gt_" + Database.getGroupToolsJSON()[gt[i]].title;
            grouptools.push(["", Database.getGroupToolsJSON()[gt[i]]]); // the array should be structured like the cards array to use the same initCard function
        })

        level0.forEach(function (el, i) {
            let obj = Object.keys(Database.getCardsJSON()[level0[i]]);
           // console.log (obj);
            level1.push(obj);

            let size = Object.keys(obj).length;
            for (let k = 0; k < size; k++) {
                let c = Object.entries(Database.getCardsJSON()[level0[i]][level1[i][k]].cards);
                c.forEach(function (el, l) { // give each card a unique id and assign them their parent cardtype
                    el[1].id = i + "_" + k + "_" + l;
                    el[1].cardtype = level0[i];
                })
                let ar = Object.entries(Database.getCardsJSON()[level0[i]][level1[i][k]].cards);
                level2.push(ar)

            }

        })
        // console.log("LVL0", level0);
        // console.log("LVL1", level1);
        // console.log("LVL2", level2);

    }
 
    initActivitiesContainer () {
        if (SessionData.totalUsers == 0) return;
        
        $('.activitiescontainer').empty();
        
        let limitActivitiesPerUser = Config.activitiesAllowed / SessionData.totalUsers;
        let limitActivitiesPerUserRounded = Math.floor ( limitActivitiesPerUser ) ;
        for (let i = 0; i < limitActivitiesPerUserRounded; i++) {
            $('.activitiescontainer').append("<div class='slot hexagon'></div>");
        }
    }
   
    view() {
        let c = -1;
        if ( SessionData.currentGameStep > Object.keys(Database.getGameStepsJSON().steps).length - 1) {
            // game is over 
            return;
        }
        

        let wk_class = (activitiesToDrop.length == 0) ? "hide" : "";
        wk_class += (window.innerWidth < 1400) ? " large" : "";

        return [

            m("div", {id:"mouseprogress", class:"hide"}),

            
            // THE BOX WHERE THE ACTIVITIES ARE COLLECTED
            m("div", {id:"activitiesbox", class: wk_class }, [
                m("div", {class:"title"}, Database.getUIJSON().activitiescontainertitle),
                m("div", {class:"activitiescontainer"}),
                m("button", { id:"dropactivities", class: "swal2-confirm swal2-styled",
                                onclick: this.dropActivities,
                                "aria-label":Database.getUIJSON().add_activities_btn,
                            }, Database.getUIJSON().add_activities_btn )
            ]),

            // DELETE BUTTON
            m("button", {
                class: "deletebtn hide",
                onclick: toolbarHelper.removeCard
            }, "-"), 


            // TOOLTIP
            m("i", {id:"tooltip"}, [m("span", "")]),

            /// GROUP TOOLBAR
            m("div", {
                class: "grouptools hide"
            }, [


                grouptools.map(function (d, i) {
                    return m("div", {class: "hide grouptoolbar " + d[1].class, id:d[1].id, onclick: _this.selectGroupType}, [
                        m("div", {class:"tool"}, [
                            m("i", {class: d[1].icon}),
                            m("div", {class: "title"}, d[1].title),
                        ]),
                    ])
                }),


            ]),



            // MAIN TOOLBAR
            m("div", {
                class: "menu"
            }, [
                m("nav", {
                    id: "stack-menu"
                }, [
                    m("ul", [
                        
                        level0.map(function (title, i) {
                            let _iconclass;
                            if (title == Config.cardtypes.activity) _iconclass = "hexagon-fill"; 
                            if (title == Config.cardtypes.room) _iconclass = "square-fill";
                            if (title == Config.cardtypes.atmosphere) _iconclass = "triangle-fill";
                            return [
                                m("li", {"aria-label": title, class: title + " hide"}, 
                                m("i", {class:"bi " + _iconclass}),
                                [
                                m("a", title),
                                m("ul", {id:title}, [

                                    m("input", {type:"text", class:"search", id:"search_"+i, 'aria-label': "Suche", placeholder:"Suche...", onkeyup: () => {
                                        $('#'+title).scrollTop(0);
                                        let input, filter, li, a, txtValue;
                                        input = document.getElementById('search_'+i);
                                        filter = input.value.toUpperCase();
                                        if (filter.length > 0) $('.joker.'+title).fadeIn();
                                        if (filter.length == 0) $('.joker.'+title).fadeOut();
                                        $('.joker.'+title).html(input.value);
                                        li = $("#"+title + " .subsubchildren li");//ul.getElementsByTagName('li');

                                      
                                        // Loop through all list items, and hide those who don't match the search query
                                        for (let i = 0; i < li.length; i++) {
                                            a = li[i].getElementsByTagName("a")[0];
                                            txtValue = a.textContent || a.innerText;
                                            if (txtValue.toUpperCase().indexOf(filter) > -1) {
                                                li[i].style.display = "";
                                           
                                            } else {
                                                li[i].style.display = "none";
                                           
                                            }
                                        }

                                        // go through subcontainers and check if everything is hidden                                        
                                        $( "#" + title + " .subcontainer" ).each(function( index ) {
                                         //   console.log( index + ": " + $( this ).find('.tab').html(), $( this ).find('.subsubchildren li:visible').length );
                                            $(this).css("display", "block");
                                            if ( $( this ).find('.subsubchildren li:visible').length == 0) {
                                                $(this).css("display", "none");
                                            } 
                                          });

                                    }}),
                                    m("div", {class:"joker " + title, 
                                        onclick: function() { 
                                            
                                            let j;
                                            if (title == Config.cardtypes.activity) j = 0;
                                            if (title == Config.cardtypes.atmosphere) j = 1;
                                            if (title == Config.cardtypes.room) j = 2;
                                            _this.customCard(title, document.getElementById('search_'+j).value) 
                                        }
                                    }, "joker"),
                                    level1[i].map(function (title, j) {
                                        c++;
 
                                        return m("div", {class:"subcontainer"}, [
                                            m("li",[
                                                m("a", {class:"tab"}, title),
                                                
                                                
                                                m("ul", [
                                                    level2[c].map(function (d, k) {
                                                        return m("li", {                                                        
                                                            id: d[1].id
                                                        }, [m("a", {
                                                            "aria-label": d[1].title,
                                                            style:"background-image:url(" + Config.cardimagesfolder + "full/"+ d[1].image + ")",
                                                            //style:"background-image:url(" + Config.cardimagesfolder + "/activities/"+ d[1].image + ")",
                                                            onclick: (e) => {
                                                                e.redraw = false;
                                                                if ($('#'+d[1].id).hasClass("used-card")) return;
                                                                _this.initCard(d); 
                                                            }
                                                        }, m("span",m.trust(d[1].title)))])
                                                    })
                                                ])
                                            ])
                                        ])
                                    })
                                ])

                            ])
                        ]
                        }),

/*
                        // create custom activity card
                        m("li", {
                            class:"activityjoker hide",
                            onclick: function() { _this.customCard(Config.cardtypes.activity) }
                        }, [
                            m("a", Database.getUIJSON().activity_joker),
                            m("i", {class:"bi bi-hexagon-half"})    
                        ]), 

                        // create custom atmosphere card
                        m("li", {
                            class: Config.cardtypes.atmosphere + " hide",
                            onclick: function() { _this.customCard(Config.cardtypes.atmosphere) }
                        }, [m("a", Database.getUIJSON().atmosphere_joker),
                            m("i", {class:"bi bi-triangle-half"})
                        ]), 


                         // create custom room card
                         m("li", {
                            class: Config.cardtypes.room + " hide",
                            onclick: function() { _this.customCard(Config.cardtypes.room) }
                        }, [m("a", Database.getUIJSON().room_joker),
                            m("i", {class:"bi bi-square-half"})
                        ]), 

*/
                        // show time joker
                        m("li", {
                            class: "timejoker hide",
                            onclick: toolbarHelper.clickedTimeJoker,
                            "aria-label": Database.getUIJSON().add_extra_time_joker
                        }, [
                            m("a", Database.getUIJSON().add_extra_time_joker + " (" + SessionData.timeJoker + " Minuten)" ),
                            m("i", {class:"bi bi-hourglass-split"})
                        ]), 



                        // MAIN TOOLS
                        maintools.map(function (d, i) {
                            let _iconclass;
                            if (d[1].title == "Ankommen") _iconclass = "bi-caret-up-square-fill";
                            if (d[1].title == "mittelpunkt") _iconclass = "bi-record-circle-fill";
                            return m("li", {
                                class: d[1].title + " hide",
                                "aria-label": d[1].title,
                                onclick: (e) => {
                                    e.redraw = false;

                                    d[1].id = "mt_" + d[1].title + "_" + Date.now(); // give the maintools a unique ID, to be able to proper map the sockets

                                    _this.initCard(d);
                                }
                            }, [
                                m("a", d[1].title),
                                m("i", {class:"bi " + _iconclass})]) 

                        }),
                       

                    ])
                ])

            ]) // END MAIN TOOLBAR
        ]
    }

    customCard (type, text) {
        let d; 
        if (type == Config.cardtypes.activity) { 
            d = ["", {
                title: text,
                cardtype: Config.cardtypes.activity,
                id: "activity_" + Date.now(),
                image: "activity_joker.png"
            }]
        } else if (type == Config.cardtypes.atmosphere) {
            d = ["", {
                title: text,
                cardtype: Config.cardtypes.atmosphere,
                id: "atmosphere_" + Date.now(),
                image: "atmosphere_joker.png"
            }]
        } else if (type == Config.cardtypes.room) {
            d = ["", {
                title: text,
                cardtype: Config.cardtypes.room,
                id: "room_" + Date.now(),
                image: ""
            }]
        }

        _this.initCard (d);

    }



    selectGroupType(e) {
        let id = $(this).attr('id');
        let stroke;

        switch (id) {
            case "access_safe":
                stroke = [5, 5];
                _this.drawCircle("access", false, "rgba(0,0,255,0)");
                break;
            case "access_open":
                stroke = [5, 5];
                _this.drawCircle("access", true, "rgba(0,0,255,0)");
                break;

            case "loudness_loud":
                stroke = stroke;
                _this.drawCircle("loudness", false, "rgba(255,0,0,0.3)");
                break;

            case "loudness_quiet":
                stroke = stroke;
                _this.drawCircle("loudness", false, "rgba(0,0,255,0.3)");
                break;


            case "create_connection":
                _this.createConnection(e);
                break;


            case "create_city":
                _this.createCityConnection(e);
                break;

            case "create_group_title":
                _this.createGroupTitle(e);
                break;
        }

    }

    createGroupTitle(e) {

        
        let group = SessionData.canvas.getActiveObject();
        let allactivities = [];
        let allatmospheres = [];
        let allrooms = [];
        group._objects.forEach(item => {
            
            if (item.cardtype == Config.cardtypes.atmosphere) allatmospheres.push(item);
            if (item.cardtype == Config.cardtypes.activity) allactivities.push(item);
            if (item.cardtype == Config.cardtypes.room) allrooms.push(item);     
        })

        
        modal.addGroupTitle(allactivities, allatmospheres, allrooms, group).then((v) => {
          
            let grouptitle = v.atmospheretitle + " - " + v.activitytitle + " - " + v.roomtitle;
            if (v) {
                let group = SessionData.canvas.getActiveObject();
                // rename group tile
                let titleexists = false;
                if (group == undefined) return;
                group._objects.forEach(function (el, i) {
                    if (el.cardtype == "grouptitle") {
                        
                        el.set('text', grouptitle);
                        SessionData.canvas.renderAll();
                        titleexists = true;


                        SessionData.socket.emit("createGroupTitle", {
                            id: el.id,
                            groupID: group.id,
                            title: grouptitle,
                            username: SessionData.Name,
                            room: SessionData.roomname
                        })
                    }
                })
                if (titleexists) return;

                let text = new fabric.Text(grouptitle, {
                    originX: 'left',
                    originY: 'top',
                    fontSize: 14,
                    backgroundColor: "#000",
                    fill: "#FFF",
                    fontFamily: 'Karla',
                    top: group.top - 15
                })
                text.cardtype = "grouptitle";
                text.id = "grouptitle_" + Date.now();
                SessionData.objectsOnCanvas.push(text);
                text.left = group.left + group.width/2 - text.width/2;
                
                
                group.addWithUpdate(text);
                group.setCoords();                
                SessionData.socket.emit("createGroupTitle", {
                    id: text.id,
                    groupID: group.id,
                    title: grouptitle,
                    username: SessionData.Name,
                    room: SessionData.roomname
                })

                SessionData.canvas.renderAll();
            }
        })
    }


    createCityConnection(e) {
        let activities = 0;
        modal.addCardTitle().then((v) => {
            if (v) {
                let group = SessionData.canvas.getActiveObject();

                // rename city tile
                let cityexists = false;
                if (group == undefined) return;
                group._objects.forEach(function (el, i) {
                    if (el.cardtype == Config.cardtypes.activity) activities++;
                    if (el.cardtype == Config.cardtypes.city) {
                        el._objects[1].set('text', v);

                        SessionData.socket.emit("createCityConnection", {
                            id: group.id,
                            groupID: group.id,
                            title: v,
                            username: SessionData.Name,
                            room: SessionData.roomname
                        })
                        SessionData.canvas.renderAll();
                        cityexists = true;
                    }
                })
                if (cityexists) return;


                toolbarHelper.createImage(Config.cardimagesfolder + "Stadtsatellit.png").then((oimg) => {
                    oimg.scaleToWidth(50);

                    let text = new fabric.Text(v, {
                        originX: 'center',
                        originY: 'center',
                        fontFamily: 'Karla',
                        fontSize: 9,
                        left: 0.5 * 50,
                        top: 55
                    })
                    let g = new fabric.Group([oimg, text], {
                        left: (activities % 2 == 0) ? group.left + group.width - 30 : group.left + group.width - 75,
                        top: group.top + group.height - 25,
                        originX: 'left',
                        originY: 'top',
                        centeredRotation: true,
                        hasControls: false
                    });
                    g.cardtype = Config.cardtypes.city;
                    g.id = "city_" + Date.now();
                    SessionData.objectsOnCanvas.push(g);

                    group.addWithUpdate(g);
                    group.setCoords();

                    SessionData.socket.emit("createCityConnection", {
                        id: g.id,
                        groupID: group.id,
                        title: v,
                        username: SessionData.Name,
                        room: SessionData.roomname
                    })


                    SessionData.canvas.renderAll();


                });

            }
        });


    }


    // add connecting line from group to dot
    createConnection(e) {
        let pointer = SessionData.canvas.getPointer(e);
        let group = SessionData.canvas.getActiveObject();
       
        let points = [group.left + group.width/2, group.top + group.height/2, pointer.x, pointer.y+50];

        // make a line next to the group and the circle controller
        let line = _this.makeLine(points);
        let controller1 = _this.makeLineController(pointer.x, pointer.y+50, line, group);
        
        SessionData.objectsOnCanvas.push(line);
        SessionData.objectsOnCanvas.push(controller1);
        
        // group.line connect groups done
        if (group.lineFrom == undefined) group.lineFrom =[line.id];
        if (group.lineFrom != undefined) group.lineFrom.push(line.id); // assign the line to the group, so we can manipulate it when we move the group
        
        SessionData.canvas.add(line);
        SessionData.canvas.add(controller1);
        
        SessionData.canvas.bringToFront(line);
        SessionData.canvas.bringToFront(controller1);
        SessionData.canvas.sendToBack(group);
  
        SessionData.socket.emit("createConnection", {
            groupID: SessionData.canvas.getActiveObject().id,
            controllerID: controller1.id,
            lineID: line.id,
            pointer: pointer,
            username: SessionData.Name,
            room: SessionData.roomname
        });
    }

    makeLineController(left, top, line, group) {
        var c = new fabric.Circle({
            left: left,
            top: top,
            strokeWidth: 5,
            id: "lc_" + Date.now(),
            radius: 4,
            fill: '#fff',
            stroke: 'red',
            hasControls: false,
            hasBorders: false,
            cardtype: Config.cardtypes.linecontroller,
            originIsland: group.id
        });

        
        line.linecontroller = c.id;
        c.line = [line.id]; // assign the line to the circle, so we can move them together
        return c;
    }

    makeLine(coords) {
        let l = new fabric.Line(coords, {
            stroke: 'red',
            strokeWidth: 5,
            strokeDashArray: [5, 5],
            id: "line_" + Date.now(),
            selectable: true,
            perPixelTargetFind: true,
       //     targetFindTolerance: true,
            hasControls: false,
            hasBorders: true,
            lockMovementX: true,
            lockMovementY: true,
            hoverCursor: "default"
            //evented: false,
        });
        l.cardtype = Config.cardtypes.line;
        return l;
    }

    drawCircle(type, stroke, fill) {
        
        toolbarHelper.createCircle (type, stroke, fill);

        SessionData.socket.emit("createcircle", {
            type: type,
            stroke: stroke,
            fill: fill,
            groupID: SessionData.canvas.getActiveObject().id,
            username: SessionData.Name,
            room: SessionData.roomname
        });
    }


    
    

    initCard(data) {
        let exists = false;
        // check if the card is already on board. If so don't add
        SessionData.objectsOnCanvas.forEach(function(obj) {
            if (obj.id == data[1].id) {
                console.log (obj.id, "exists");
                $('#'+obj.id).addClass("used-card");
                exists = true;
                return;
            }

            if (obj.cardtype == Config.cardtypes.island) {
                obj._objects.forEach(function(_obj) {        
                    if (_obj.id == data[1].id) {
                        console.log (_obj.id, "exists");
                        $('#'+_obj.id).addClass("used-card");
                        exists = true;
                        return;
                    }
                })
            }
        });
        if (exists) return;

        if (data[1].cardtype == Config.cardtypes.activity) {
            SessionData.activitiesonboard += 1;
            SessionData.activitiesiadded += 1;
        }

        //limit the amount of activities on the canvas
        if (SessionData.activitiesonboard > Config.activitiesAllowed && data[1].cardtype == Config.cardtypes.activity) {
            SessionData.activitiesonboard = Config.activitiesAllowed;
            let txt_data = {activitieslimit: Config.activitiesAllowed}
            let msg = Utils.applyTemplate(Database.getUIJSON().no_more_activities, txt_data);
            
            modal.errorNotification(msg);
            return;
        }

        //  limit the amount of activities a user can add
        let limitActivitiesPerUser = Config.activitiesAllowed / SessionData.totalUsers;
        let limitActivitiesPerUserRounded = Math.floor ( limitActivitiesPerUser ) ;
        
        // limit the activities per use only during "warenkorb" step
        if (SessionData.activitiesiadded > limitActivitiesPerUserRounded && SessionData.currentGameStep == Config.stepForActivitiesContainer) { 
            SessionData.activitiesiadded = limitActivitiesPerUserRounded;
            SessionData.activitiesonboard -= 1;
            let txt_data = {activitieslimit: limitActivitiesPerUserRounded}
            let msg = Utils.applyTemplate(Database.getUIJSON().no_more_user_activities, txt_data);
            
            modal.errorNotification(msg);
            return;
        }

        // move the card to the "warenkorb" first
        if (data[1].cardtype == Config.cardtypes.activity && SessionData.currentGameStep == Config.stepForActivitiesContainer) {
            $('#activitiesbox .activitiescontainer .slot:nth-child('+SessionData.activitiesiadded+')').append("<img tooltip='" + Database.getUIJSON().undoWKTxt +"' distx='25' disty='0' id='wk-" + data[1].id + "' src='" +Config.cardimagesfolder +"full/"+ data[1].image +"' />"); // add the image to the container
            $('#activitiesbox .activitiescontainer .slot:nth-child('+SessionData.activitiesiadded+')').append("<div class='title' id='wk-desc-" + data[1].id + "'>" + data[1].title + "</div"); // add the image to the container
            $('#activitiesbox .activitiescontainer .slot:nth-child('+SessionData.activitiesiadded+')').addClass("slot-"+ data[1].id);
            $('#' + data[1].id).addClass("used-card");// hide it from the toolbar
            
            // emit that the activity is added/removed from the container so that it's not available to the other players
            SessionData.socket.emit("activitysaved", {
                id: data[1].id,
                room: SessionData.roomname
            });

            activitiesToDrop.push (data[1]);
            // clicking the image removes it from the container and adds it back to the toolbar
            $( "#wk-"+data[1].id ).click( () => {
                $('#' + data[1].id).removeClass("used-card");

                $('.slot-' + data[1].id).appendTo("#activitiesbox .activitiescontainer");
                $('.slot-' + data[1].id).removeClass("slot-"+data[1].id);
                $("#wk-" + data[1].id).remove();
                $("#wk-desc-" + data[1].id).remove();
                SessionData.activitiesiadded -= 1;
                SessionData.activitiesonboard -= 1;
                

                SessionData.socket.emit("activityunsaved", {
                    id: data[1].id,
                    room: SessionData.roomname
                });

                activitiesToDrop = activitiesToDrop.filter(item => item.id !== data[1].id);
            });

            m.redraw();
            
            return;
        }

     
        let analysis = (data[1].analysis != undefined) ? data[1].analysis : "";
        let card = this.makeCard(data[1].title, data[1].cardtype, data[1].id, data[1].image, analysis).then(function (r) {
            // hide toolbar item
            $('#' + data[1].id).addClass("used-card");

            SessionData.socket.emit("objectadded", {
                title: data[1].title,
                id: data[1].id,
                cardtype: data[1].cardtype,
                image: data[1].image,
                left: card.left,
                top: card.top,
                scaleX: card.scaleX,
                scaleY: card.scaleY,
                angle: card.angle,
                analysis: analysis,
                username: SessionData.Name,
                room: SessionData.roomname
            });
        });




    }

 
    makeCard(title, cardtype, id, image, analysis) {
      //  console.log("CREATED", cardtype, id);

        $("#"+id).addClass("used-card");

        return new Promise((resolve, reject) => {
            let card;

            switch (cardtype){
                case Config.cardtypes.activity:
                    card = toolbarHelper.createActivityCard(image, title, id, analysis);
                    resolve(card);
                break;

                case Config.cardtypes.room:
                    card = toolbarHelper.createRoomCard (title, id, analysis);
                    resolve(card);
                break;

                case Config.cardtypes.atmosphere:
                    card = toolbarHelper.createAtmosphereCard (image, title, id);
                    resolve(card);
                break;
                case Config.cardtypes.ankommen:
                    card = toolbarHelper.createAnkommenCard (image, title, id);
                    resolve(card);
                break;
            }

        });

    }


    





}

export default Toolbar;