import $ from 'jquery';
import Utils from './utils.class';
import Database from './database.class';
import SessionData from './session.class';
import Config from '../config';
import modal from './../views/modals.class';
import boardLayout from './boardLayout.class';
class toolbarHelper {





    static enableTimeJoker() {
        $(".timejoker").removeClass("hide");
    }

    static clickedTimeJoker(e) {
        e.redraw = false; // don't redraw the canvas

        // update the duration of all progressbars

        let tj = Database.getGameStepsJSON().steps["step" + SessionData.currentGameStep].timejoker;
        
        if (tj == 0) tj = SessionData.timeJoker;
        console.log("%cCLICKED TIME JOKER for " + tj + " minutes", "background:#FFF;color:#000");

        Utils.updateProgressbarDuration(tj);
        SessionData.allTimeJokers += tj; // update the total of all timejokers used to take into account for the totalprogressbar

        toolbarHelper.disableTimeJoker();
        // notify everyone about the new time
        SessionData.socket.emit("updateProgressbarDuration", {
            extratime: tj,
            username: SessionData.Name,
            room: SessionData.roomname
        });

    }

    static disableTimeJoker() {
        $(".timejoker").addClass("hide");
    }

    static createImage(imgUrl) {

        return new Promise((resolve, reject) => {
            fabric.Image.fromURL(imgUrl, function (oimg) {
                resolve(oimg);
            });
        });
    }

    static createAnkommenCard(image, title, id) {
        
        toolbarHelper.createImage(Config.cardimagesfolder + image).then((oimg) => {
            let rectfill = "#FFF";
            let textfill = "#000";
            let texttop = 50;
            let box_width = 100;

            oimg.scaleToWidth(box_width);
            oimg.top = 5;

            let objs = [oimg];
            let posl = $(window).width()/2 - box_width/2;
            let post = $(window).height()/2 - box_width/2;

            let g = new fabric.Group(objs, {
                left: posl,
                top: post,
                originX: 'center',
                originY: 'center',
                centeredRotation: true,
                hasControls: true,
                lockScalingX: true,
                lockScalingY: true
            });

            g.cardtype = Config.cardtypes.ankommen;
            g.id = id;
            g.title = title;
            g.setControlsVisibility({
                mt: false, 
                mb: false, 
                ml: false, 
                mr: false, 
                bl: false,
                br: false, 
                tl: false, 
                tr: false,
                mtr: true, 
           });
            SessionData.objectsOnCanvas.push(g);
            SessionData.canvas.add(g);
            SessionData.canvas.bringToFront(g);
            
           
            function pulse () {
                let scale = 1.25;
                let targetScaleX = (g.scaleX == scale) ? 1 : scale;
                let targetScaleY = (g.scaleY == scale) ? 1 : scale;
                g.animate({scaleX: targetScaleX, scaleY:targetScaleY}, {
                    duration:250,
                    onChange: SessionData.canvas.renderAll.bind(SessionData.canvas),
                    easing: fabric.util.ease['easeInOutCubic']
                })
            }

            g.interval = setInterval(pulse, 500);
            
            
        });

    }
    
    static createAtmosphereCard(image, title, id) {
        toolbarHelper.createImage(Config.cardimagesfolder + image).then((oimg) => {
            let rectfill = "#FFF";
            let textfill = "#000";
            let texttop = 30;
            let box_width = 50;

            oimg.scaleToWidth(box_width);
            let text = new fabric.Textbox(title, {
                originX: 'center',
                originY: 'center',
                fontFamily: 'Karla',
                fontSize: 9,
                width: box_width, 
                textAlign: 'center',
                left: 0.5 * box_width,
                fill: textfill,
                top: texttop
            })

            let objs = [oimg, text];
            let posl = $(window).width() - 300;
            let post = Utils.getRandomInt(50, $(window).height() - 50);

            let g = new fabric.Group(objs, {
                left: posl,
                top: post,
                originX: 'left',
                originY: 'top',
                centeredRotation: true,
                hasControls: false,
                lockScalingX: true,
                lockScalingY: true
            });

            g.cardtype = Config.cardtypes.atmosphere;
            g.id = id;
            g.title = title;

            SessionData.objectsOnCanvas.push(g);
            SessionData.canvas.add(g);
            SessionData.canvas.bringToFront(g);

            return (g);
        });

    }


    static createRoomCard(title, id, analysis) {
        let rectfill = "#000";
        let textfill = "#FFF";
        let texttop = 25;
        let box_width = 50;

        let rectbg = new fabric.Rect({
            fill: rectfill,
            width: box_width,
            left: 0,
            height: box_width
        });
        let text = new fabric.Textbox(title, {
            originX: 'center',
            originY: 'center',
            fontFamily: 'Karla',
            fontSize: 9,
            width: box_width,
            textAlign: 'center',
            left: 0.5 * box_width,
            fill: textfill,
            top: texttop
        });

        let objs = [rectbg, text];
        let posl = Utils.getRandomInt($(window).width() / 3, ($(window).width() / 2) + ($(window).width() / 3));
        let post = Utils.getRandomInt($(window).height() / 3, ($(window).height() / 2) + ($(window).height() / 3));
        let g = new fabric.Group(objs, {
            left: posl,
            top: post,
            originX: 'left',
            originY: 'top',
            centeredRotation: true,
            hasControls: false,
            lockScalingX: true,
            lockScalingY: true
        });
        g.analysis = analysis;
        g.cardtype = Config.cardtypes.room;
        g.id = id;
        g.title = title;

        SessionData.objectsOnCanvas.push(g);
        SessionData.canvas.add(g);
        SessionData.canvas.bringToFront(g);

        return (g);
    }

    static createActivityCard(image, title, id, analysis) {
        toolbarHelper.createImage(Config.cardimagesfolder +  "full/" + image).then((oimg) => {
            let textfill = "#FFF";
            let texttop = 50;
            let box_width = 80;
            oimg.scaleToWidth(100);
            oimg.top = -20;
            oimg.left = -24;
            
            title = title.replace(/<br\s*[\/]?>/gi, "\n");
            let text = new fabric.Textbox(title, {
                originX: 'center',
                originY: 'center',
                fontFamily: 'Karla',
                fontSize: 9,
                width: box_width,
                textAlign: 'center',
                left: 27,
                fill: textfill,
                top: texttop,
                shadow: new fabric.Shadow({color:'black',offsetX:.5, offsetY:.5}),
         //       backgroundColor: "#CCC",
                lineHeight:1
            })
            

            let objs = [oimg, text];
            // position element randomly
            let posl = Utils.getRandomInt($(window).width() / 3, ($(window).width() / 2) + ($(window).width() / 3));
            let post = Utils.getRandomInt($(window).height() / 3, ($(window).height() / 2) + ($(window).height() / 3));

            let g = new fabric.Group(objs, {
                left: posl,
                top: post,
                originX: 'left',
                originY: 'top',
                centeredRotation: true,
                hasControls: false,
                lockScalingX: true,
                lockScalingY: true
            });
            g.analysis = analysis;
            g.cardtype = Config.cardtypes.activity;
            g.id = id;
            g.title = title;

            SessionData.objectsOnCanvas.push(g);
            SessionData.canvas.add(g);
            SessionData.canvas.bringToFront(g);

            return g;
        })


    }

    static createGroupFromObjects(movingobject, targetobject, id) {
       // console.log(movingobject.id + " is intersecting with card: " + targetobject.id + ". If you drop, create group");

        if (targetobject == undefined) return;
        if (movingobject == undefined) return;
        
        movingobject.left = targetobject.get('left') + boardLayout.activitiesPositions()[0].left;
        movingobject.top = targetobject.get('top') + boardLayout.activitiesPositions()[0].top;
        movingobject.opacity = 1;
        targetobject.opacity = 1;
        let g = new fabric.Group([targetobject, movingobject], {
            subTargetCheck: true
        }); // group selected objects
        g.cardtype = Config.cardtypes.island;
        g.id = id;

        if (g.lineFrom != undefined) g.lineFrom = g.lineFrom;
        if (g.lineFrom == undefined) g.lineFrom = [];
        
        toolbarHelper.groupMouseEvents(g);


        // remove the objects we add to the group from the list of single objects we have
        Utils.removeObjectById(targetobject.id);
        Utils.removeObjectById(movingobject.id);

        SessionData.canvas.add(g);

        // add the group in the list of objects we have
        SessionData.objectsOnCanvas.push(g);
        SessionData.canvas.discardActiveObject();
    }

    static createCircle (type, stroke, fill, _group) {
        let group;
        if (_group == undefined) group = SessionData.canvas.getActiveObject();
        if (_group != undefined) group = _group;
        
        let strokeArray;
        
        if (stroke) strokeArray = [5, 5];
        if (!stroke) strokeArray = [0, 0];
        let circleexists = false;

        group._objects.forEach(function (el, i) {
            if (el.cardtype == Config.cardtypes.opencircle || el.cardtype == Config.cardtypes.safecircle) {
                if (type == "access") el.set("strokeDashArray", strokeArray);
                if (type == "access") el.set('stroke','black');
                if (type =="loudness") el.set("fill", fill);
                
                circleexists = true;
                
                el.cardtype = (el.strokeDashArray[0] == 5) ? Config.cardtypes.opencircle : Config.cardtypes.safecircle;
                
                
                SessionData.canvas.renderAll();
            }
        })

        if (circleexists) return;

        let r; // circle radius
        let t; // top position of circle
        let l; // left position of circle
        
        if (group.width >= group.height) {
            
            r = group.width / 2;
            t = group.top - (r/2);
            l = group.left;
        } else {
            
            r = group.height / 2;
            t = group.top;
            l = group.left - r + group.width/2;
        }
        
        let circle = new fabric.Circle({
            left: l,
            top: t,
            radius: r,
            stroke: (type == "loudness") ? 'rgba(0,0,0,0)' : 'black',
            strokeDashArray: strokeArray,
            strokeWidth: 1,
            hasControls: false,
            fill: fill
        });
        group.addWithUpdate(circle);
        circle.sendToBack();
        circle.cardtype =  (stroke) ? Config.cardtypes.opencircle : Config.cardtypes.safecircle;
        
        group.setCoords();
        SessionData.canvas.renderAll();
    }

    static assignEvaluationTag (target) {
        
        if (target == undefined) return;
        if (target.cardtype != Config.cardtypes.island && target.cardtype != Config.cardtypes.activity) return; // allow tagging of activities and island only
        
      //  console.log ("target island? ", SessionData.evaluationTargetIsland);
        if (!SessionData.evaluationTargetIsland && target.cardtype == Config.cardtypes.island) return;
     //   console.log ("ASSIGN EVAL TAG", target);
        let currentTag = Database.getGameStepsJSON().steps["step" + SessionData.currentGameStep].evaluation.tag;
        if (currentTag == "") currentTag = $("#text_tag").val(); // leave the tag field empty to get tag info from input text
        let hasScale = Database.getGameStepsJSON().steps["step" + SessionData.currentGameStep].evaluation.scale;
        // let foundTag;
        // if (e.target.tags != undefined) {
        //     foundTag = e.target.tags.find(e => e.tag == currentTag)
        // }
        // console.log ("FOUND", foundTag);

        
        let _tag = {
            "tag": currentTag,
            "scale": (hasScale) ? 2 : "noscale"
        }
        if (hasScale) document.getElementById('slideramount').value = 2;

        if (hasScale) {
            // slider position should consider zoom level
            $('#slider').css("left", Utils.getAbsoluteCoords(target).left + "px");
            $('#slider').css("top", Utils.getAbsoluteCoords(target).top + "px");
            
            let slider = document.getElementById("range");
            slider.value = 2;
            let _obj = target;
           
            slider.oninput = function() {
                _obj.tags.filter( (item, index) => {
                    if (item.tag == _tag.tag) { 
                        _obj.tags[index].scale = this.value; // update scale if we're moving the range
                        document.getElementById('slideramount').value=this.value; 
                        SessionData.socket.emit("updateEvaluationTag", {
                            room: SessionData.roomname,
                            id: _obj.id,
                            index: index,
                            value: this.value 
                        }) 
                    }
                }); 
            }
        }
        
        toolbarHelper.saveTag(target, _tag);
        SessionData.socket.emit("assignEvaluationTag", {
            room: SessionData.roomname,
            id: target.id,
            tag: _tag 
        })        
    }
 
    static saveTag (target, _tag) {
        
        let shadow = new fabric.Shadow({color: 'black',blur: 30});
        // 1a. push tag with scale to target object
        if (target == undefined) return;
        if (target.tags == undefined) target.tags = [];
        // 1b. if tag exists remove it
        if (target.tags.some(e => e.tag === _tag.tag)) {
            target.tags = target.tags.filter(item => item.tag !== _tag.tag); // TAG EXISTS, REMOVE IT
            target.set({ shadow: null });
            if(target.cardtype == Config.cardtypes.activity) target._objects[0].set({ shadow: null }); //highlight object as evaluated
            if(target.cardtype == Config.cardtypes.island) target.set({ shadow: null }); //highlight object as evaluated
            $('#slider').fadeOut();

        } else {
            target.tags.push(_tag); // TAG DOESN'T EXIST, ADD IT
            if(target.cardtype == Config.cardtypes.island) target.set({ shadow: shadow }); //highlight object as evaluated
            if(target.cardtype == Config.cardtypes.activity) target._objects[0].set({ shadow: shadow }); //highlight object as evaluated
            $('#slider').fadeIn();
        }
        SessionData.canvas.renderAll();

    }

 
    static groupMouseEvents(g) {
        let moving = false; // check if we're moving a group

        SessionData.canvas.on('object:moved', (e) => {
            moving = false;
        });
        SessionData.canvas.on('object:moving', (e) => {
            moving = true;
        });
        
        
        
        g.on('mousedown', (e) => {
            if ( e.subTargets[0] == undefined) return;
            if (SessionData.evaluation && !SessionData.evaluationTargetIsland) toolbarHelper.assignEvaluationTag(e.subTargets[0]);

            if (e.subTargets[0].cardtype == Config.cardtypes.opencircle || 
                e.subTargets[0].cardtype == Config.cardtypes.safecircle ||
                e.subTargets[0].cardtype == "grouptitle"
                ) return;
            SessionData.detachTimeout = setTimeout(() => { // wait 500ms before starting
                //$("#mouseprogress").removeClass("hide");
                SessionData.detachInterval = window.setInterval(() => {
                    if (moving) { // this shouldn't trigger if user is dragging the group    
                        SessionData.detachCounter = 0;
                        $("#mouseprogress").addClass("hide");
                        return;
                    } else {
                        $("#mouseprogress").removeClass("hide");
                    }
                    
                    SessionData.detachCounter = parseInt(SessionData.detachCounter) + 1;
                    if (SessionData.detachCounter >= 8) {
                        SessionData.detachCounter = 0;
                        if (!SessionData.mousePressed) {
                            $("#mouseprogress").addClass("hide");
                            SessionData.mousePressed = true;
                            toolbarHelper.detachFromGroup(e.subTargets[0], g);
                            SessionData.socket.emit("detachFromGroup", {
                                objectID: e.subTargets[0].id,
                                groupID: g.id,
                                room: SessionData.roomname
                            });
                        }

                    }
                }, 100);
            }, 500)
        });

        // clear the interval on mouseup
        g.on('mouseup', (e) => {
            toolbarHelper.undoDetachEvent();
        })
    }

    static undoDetachEvent () {
        window.clearTimeout(SessionData.detachTimeout);
        window.clearInterval(SessionData.detachInterval);
        SessionData.detachCounter = 0;
        SessionData.mousePressed = false;
        $("#mouseprogress").addClass("hide");
    }

    static detachFromGroup(target, group) {
        SessionData.canvas.add(target);
        
        group.removeWithUpdate(target);
       // group.setCoords();

        // reposition detached element off the group
       target.left = group.left - target.width;
       target.top = group.top - target.height;

        SessionData.objectsOnCanvas.push(target);
        SessionData.canvas.discardActiveObject();
        
        // if i keep my mouse pressed, island is attached
        var evt = new MouseEvent("mouseup", {
            bubbles: true,
            cancelable: true,
            view: window
         });
         SessionData.canvas.upperCanvasEl.dispatchEvent(evt);
         toolbarHelper.undoDetachEvent();
        // if 1 card is left in the group
        if (group._objects.length == 1) {            
            // remove the group and add its left object to the canvas
            
            SessionData.canvas.add(group._objects[0]);
            SessionData.objectsOnCanvas.push(group._objects[0]);
            group.removeWithUpdate(group._objects[0]);
            
            
            Utils.removeObjectById(group.id);
        }

        boardLayout.repositionCards(group);        
    }




    static addToGroup(movingobject, targetobject) {
        
        // movingobject can be null, catch this
        if (movingobject == undefined) return;
        if (movingobject.cardtype == Config.cardtypes.linecontroller) return;
        movingobject.opacity = 1;
        
        
        boardLayout.snapCards(movingobject, targetobject);
        targetobject.addWithUpdate(movingobject);
        Utils.removeObjectById(movingobject.id); // remove the object we add to the group from the list of single objects we have
        
      //  SessionData.canvas.remove(movingobject);
        targetobject.setCoords();
        SessionData.canvas.discardActiveObject();
        SessionData.canvas.renderAll();
    }


    

    static checkGroupValidity(movingobject, targetobject) {
        // you can't merge two islands
        if (movingobject.cardtype == Config.cardtypes.island && targetobject.cardtype == Config.cardtypes.island) {
            modal.simpleNotification(Database.getUIJSON().island_in_island);
            return 0;
        }

        // console.log ("moving object", movingobject.cardtype);
        // console.log ("targetobject", targetobject.cardtype);
        
        // don't snap line controllers anywhere
        //if (movingobject.cardtype == Config.cardtypes.linecontroller) return 0;

        
        // don't snap ankommen to a group
        if (movingobject.cardtype == Config.cardtypes.ankommen) return 0;


        // can't group atmospheres with: activities, atmospheres, rooms, lines, linecontrollers
        if (movingobject.cardtype == Config.cardtypes.atmosphere && targetobject.cardtype == Config.cardtypes.activity) return 0;
        if (movingobject.cardtype == Config.cardtypes.atmosphere && targetobject.cardtype == Config.cardtypes.atmosphere) return 0;
        if (movingobject.cardtype == Config.cardtypes.atmosphere && targetobject.cardtype == Config.cardtypes.room) return 0;
        if (movingobject.cardtype == Config.cardtypes.atmosphere && targetobject.cardtype == Config.cardtypes.line) return 0;
        if (movingobject.cardtype == Config.cardtypes.atmosphere && targetobject.cardtype == Config.cardtypes.linecontroller) return 0;


        // can't group activities with: atmospheres, rooms, lines, linecontrollers
        if (movingobject.cardtype == Config.cardtypes.activity && targetobject.cardtype == Config.cardtypes.atmosphere) return 0;
        if (movingobject.cardtype == Config.cardtypes.activity && targetobject.cardtype == Config.cardtypes.room) return 0;
        if (movingobject.cardtype == Config.cardtypes.activity && targetobject.cardtype == Config.cardtypes.line) return 0;
        if (movingobject.cardtype == Config.cardtypes.activity && targetobject.cardtype == Config.cardtypes.linecontroller) return 0;

        // can't group rooms with: activities, atmospheres, rooms, lines, linecontrollers
        if (movingobject.cardtype == Config.cardtypes.room && targetobject.cardtype == Config.cardtypes.activity) return 0;
        if (movingobject.cardtype == Config.cardtypes.room && targetobject.cardtype == Config.cardtypes.atmosphere) return 0;
        if (movingobject.cardtype == Config.cardtypes.room && targetobject.cardtype == Config.cardtypes.room) return 0;
        if (movingobject.cardtype == Config.cardtypes.room && targetobject.cardtype == Config.cardtypes.line) return 0;
        if (movingobject.cardtype == Config.cardtypes.room && targetobject.cardtype == Config.cardtypes.linecontroller) return 0;

        

        // rooms to activities or islands
        // lines shouldn't be snappable neither
        // linecontrollers shouldn't be snappable

        // check the number of atmospheres & rooms in the target island 
        if (targetobject.cardtype == Config.cardtypes.island) {
            let cardsvalidity = toolbarHelper.checkCardsValidity(movingobject, targetobject);
            if (cardsvalidity == 0) return 0;

        }


        return 1;
    }


    static checkCardsValidity(movingobject, targetobject) {
        let atmosNr = 0; // limit the amount of atmospheres a group can have
        let roomsNr = 0; // limit the amount of rooms a group can have
        // check what cards are already in the group
        
        for (var j = 0; j < targetobject._objects.length; j++) {
            let obj = targetobject._objects[j];
            if (obj.cardtype == Config.cardtypes.atmosphere && movingobject.cardtype == Config.cardtypes.atmosphere) {
                atmosNr += 1;
            }
            if (obj.cardtype == Config.cardtypes.room && movingobject.cardtype == Config.cardtypes.room) {
                roomsNr += 1;
            }
        }

        // limit the amount of atmospheres & rooms in a group 
        if (atmosNr >= Config.atmospheresPerGroup) {
            let data = {
                limit: Config.atmospheresPerGroup
            };
            let content = Utils.applyTemplate(Database.getUIJSON().modal_limit_atmospheres_per_group, data);
            modal.errorNotification(content);
            return 0;
        }
        if (roomsNr >= Config.roomsPerGroup) {
            let data = {
                limit: Config.roomsPerGroup
            };
            let content = Utils.applyTemplate(Database.getUIJSON().modal_limit_rooms_per_group, data);
            modal.errorNotification(content);
            return 0;
        }

        return 1;
    }

    static connectLineToGroup (movingobject, targetobject) {
        if (movingobject == undefined || targetobject == undefined) return;
        movingobject.left = targetobject.left + targetobject.width/2;
        movingobject.top = targetobject.top + targetobject.height/2;
        let line = Utils.getObjectById(movingobject.line[0]);

        if (line != undefined) line.set ({'x2': targetobject.left + targetobject.width/2, 'y2':targetobject.top + targetobject.height/2})

        if (movingobject.cardtype == Config.cardtypes.linecontroller) Utils.removeObjectById(movingobject.id);
        
        if (targetobject.lineTo != undefined && line != undefined && line.id != undefined) targetobject.lineTo.push(line.id);
        if (targetobject.lineTo == undefined && line != undefined && line.id != undefined) targetobject.lineTo = [line.id];
    }


    static removeCard() {
        let card = SessionData.canvas.getActiveObject();
        // show toolbar item
        $('#' + card.id).removeClass("used-card");

        // remove object from the list of our objects
      //  SessionData.canvas.remove(SessionData.canvas.getActiveObject());
        Utils.removeObjectById(card.id);
       
        if (card.cardtype == Config.cardtypes.activity) {
            SessionData.activitiesonboard -= 1; // reduce the number of total activities on board
        }
        

        //if removing a line controller, remove the line too
        // if (card.line != undefined && card.cardtype == Config.cardtypes.linecontroller) {
        //     Utils.removeObjectById(card.line[0]);
        // }

        // if removing a line, remove the linecontroller too
        
        if (card.cardtype == Config.cardtypes.line) {
            
            Utils.removeObjectById(card.linecontroller);
        
        }
        $('.grouptools').addClass("hide");
        for (var i = 0; i < SessionData.objectsOnCanvas.length; i++) {
            SessionData.objectsOnCanvas[i].opacity = 1;
        }
        // emit that object is removed and add it back to the toolbar
        SessionData.socket.emit("objectremoved", {
            linecontroller: (card.cardtype == Config.cardtypes.line) ? card.linecontroller : "",
            id: card.id,
            line: (card.cardtype == Config.cardtypes.linecontroller) ? card.line[0] : "",
            room: SessionData.roomname
        });


    }

   


}
export default toolbarHelper;