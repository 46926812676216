import SessionData from './session.class';
import Utils from './utils.class';
import Config from '../config';
import Database from "./database.class";
import $ from 'jquery';
import Modals from '../views/modals.class';
import m from 'mithril';



const mediaType = {
    audio: 'audioType',
    video: 'videoType'
}
const _EVENTS = {
    exitRoom: 'exitRoom',
    enterRoom: 'enterRoom',
    startVideo: 'startVideo',
    stopVideo: 'stopVideo',
    startAudio: 'startAudio',
    stopAudio: 'stopAudio'
}

let audioallowed = true; 
let videoallowed = true;

class RoomClient {

    constructor(uservideosdiv,  mediasoupClient, socket, room_id, name, successCallback) {
        this.name = name;
        this.uservideosdiv = uservideosdiv;
        this.mediasoupClient = mediasoupClient;

        this.socket = socket;
        this.producerTransport = null;
        this.consumerTransport = null;
        this.device = null;
        this.room_id = room_id;

        this.consumers = new Map();
        this.producers = new Map();

        this.mediaType = mediaType;
        this.pauseAudio = false;
        this.pauseVideo = false;

        /**
         * map that contains a mediatype as key and producer_id as value
         */
        this.producerLabel = new Map()

        this._isOpen = false
        this.eventListeners = new Map()
        Object.keys(_EVENTS).forEach(function (evt) {
            this.eventListeners.set(evt, [])
        }.bind(this))

        this.createRoom(room_id).then(async function () {
            await this.join(name, room_id)
            this.initSockets()
            this._isOpen = true
            successCallback()
        }.bind(this))
    }

    ////////// INIT /////////

    async createRoom(room_id) {
        
        await this.socket.request('createRoom', {
            room_id
        }).catch(err => {
            console.log(err)
        })
    }

    async join(name, room_id) {     
        
        this.socket.request('join', {
            name,
            room_id
        }).then(async function (e) {
            
            
            
            if (Config.videocall) {
                const data = await this.socket.request('getRouterRtpCapabilities');
                let device = await this.loadDevice(data);

                this.device = device;
                await this.initTransports(device);

                let d = {
                    user_id: this.socket.id,
                    user_name: name
                }
        
                SessionData.users.push(d);
                m.redraw();
                
                this.produce(mediaType.video);
                this.produce(mediaType.audio);

                this.socket.emit('getProducers');
                this.event(_EVENTS.enterRoom);
            } else {
    
                let userjoined = {
                    producer_id: "nomedia",
                    producer_name: this.name,
                    producer_socket_id: SessionData.socket.id
                }
                let d = {
                    user_id: this.socket.id,
                    user_name: name
                }
        
                SessionData.users.push(d);
                m.redraw();

                Utils.createNoStreamInfo(userjoined, false, false);
            }
            
            
          //  
        }.bind(this)).catch(e => {
            console.log(e)
        })
    }

    async loadDevice(routerRtpCapabilities) {
        let device;
        
        try {
            device = new this.mediasoupClient.Device();
        } catch (error) {
            if (error.name === 'UnsupportedError') {
                console.error('browser not supported');
               // Modals.errorNotification(Database.getUIJSON().browserNotSupported);
                SessionData.errorcheck.browser = true;
            }
           // Modals.errorNotification("error loading device");
            SessionData.errorcheck.device = true;
            console.error(error)
        }
        await device.load({
            routerRtpCapabilities
        })
        return device

    }

    async initTransports(device) {
        // init producerTransport
        {
            const data = await this.socket.request('createWebRtcTransport', {
                forceTcp: false,
                rtpCapabilities: device.rtpCapabilities,
            })
            if (data.error) {
                console.error(data.error);
                return;
            }
            
            this.producerTransport = device.createSendTransport(data);

            this.producerTransport.on('connect', async function ({
                dtlsParameters
            }, callback, errback) {
                this.socket.request('connectTransport', {
                        dtlsParameters,
                        transport_id: data.id
                    })
                    .then(callback)
                    .catch(errback)
            }.bind(this));

            this.producerTransport.on('produce', async function ({
                kind,
                rtpParameters
            }, callback, errback) {
                try {
                    const {
                        producer_id
                    } = await this.socket.request('produce', {
                        producerTransportId: this.producerTransport.id,
                        kind,
                        rtpParameters,
                    });
                    callback({
                        id: producer_id
                    });
                } catch (err) {
                    errback(err);
                }
            }.bind(this))

            this.producerTransport.on("producedata" , async(parameters, callback, errback) => {
                console.log ("PD", parameters);
            })

            this.producerTransport.on('connectionstatechange', function (state) {
                switch (state) {
                    case 'connecting':
                        break;

                    case 'connected':
                        console.log ("%c producer connected", 'background: green; color: #000');
                        //localVideo.srcObject = stream
                        break;

                    case 'failed':
                        this.producerTransport.close();
                        break;

                    default:
                        break;
                }
            }.bind(this));
        }

        // init consumerTransport
        {
            const data = await this.socket.request('createWebRtcTransport', {
                forceTcp: false,
            });
            if (data.error) {
                console.error(data.error);
                return;
            }

            // only one needed
            this.consumerTransport = device.createRecvTransport(data);
            this.consumerTransport.on('connect', function ({
                dtlsParameters
            }, callback, errback) {
                this.socket.request('connectTransport', {
                        transport_id: this.consumerTransport.id,
                        dtlsParameters
                    })
                    .then(callback)
                    .catch(errback);
            }.bind(this));

            this.consumerTransport.on('connectionstatechange', async function (state) {
                switch (state) {
                    case 'connecting':
                        break;

                    case 'connected':
                        console.log ("%c consumer connected", 'background: green; color: #000');
                        //remoteVideo.srcObject = await stream;
                        //await socket.request('resume');
                        break;

                    case 'failed':
                        this.consumerTransport.close();
                        break;

                    default:
                        break;
                }
            }.bind(this));
        }

    }

    initSockets() {
  
        this.socket.on('consumerClosed', function ({
            consumer_id
        }) {
            this.removeConsumer(consumer_id)
        }.bind(this))

        /**
         * data: [ {
         * msid:
         * id:
         * name:
         * moderator:
         * }]
         */
        this.socket.on('newProducers', async function (data) { 
            if (data.length < 1 ) return;
            for (let {
                    producer_id
                } of data) {
                await this.consume(producer_id, data)
            }
        }.bind(this))

        this.socket.on('disconnect', function () {
            this.exit(true)
        }.bind(this))


    }


    //////// MAIN FUNCTIONS /////////////


    async produce(type) {
        let mediaConstraints = {};
        let audio = false;

        switch (type) {
            case mediaType.audio:
                mediaConstraints = Config.audioconstraints;
                audio = true;
                break
            case mediaType.video:
                mediaConstraints = Config.videoconstraints;
                break;
            default:
                return
            break;
        }
        if (!this.device.canProduce('video') && !audio) {
           // Modals.errorNotification("cannot produce video");
            SessionData.errorcheck.video = true;
            return; 
        }
        if (this.producerLabel.has(type)) {
            Modals.errorNotification('producer already exists for this type ' + type);
            
            return
        }

        let stream;
        try {
            stream = await navigator.mediaDevices.getUserMedia(mediaConstraints);
           // console.log("supported constraints", navigator.mediaDevices.getSupportedConstraints())

            
            const track = audio ? stream.getAudioTracks()[0] : stream.getVideoTracks()[0]
            const params = {track};
            if (!audio) {
                params.encodings = Config.videoencodings;
                params.codecOptions = Config.videocodecoptions;
            }
            
            let producer = await this.producerTransport.produce(params)

            
            this.producers.set(producer.id, producer);
            let userjoined = {
                producer_id: producer.id,
                producer_name: this.name,
                producer_socket_id: SessionData.socket.id
            }
            
            Utils.createStreamContainer(userjoined);
            if (!audio) {
                this.createUserVideoElement(stream, SessionData.socket.id, producer.id);
            } 
            
            // MS remove trackended
            producer.on('trackended', () => {
                console.log ("++ PRODUCER TRACKENDED", producer);
                this.closeProducer(type);
            })
                        
            producer.on('transportclose', () => {
                console.log('producer transport close', producer);
                if (!audio) {
                    let elem = document.getElementById(producer.id);

                    elem.srcObject.getTracks().forEach(function (track) {
                        track.stop();
                    });
                    $("#"+producer.id).parent().remove();
                }
                this.producers.delete(producer.id)

            })

            producer.on('close', () => {
                console.log('closing producer');
                let elem = document.getElementById(producer.id)
                if (!audio) {
                    elem.srcObject.getTracks().forEach(function (track) {
                        track.stop()
                    })
                    
                    elem.parentNode.removeChild(elem)
                }
                this.producers.delete(producer.id)

            })

            this.producerLabel.set(type, producer.id)


            

            switch (type) {
                case mediaType.audio:
                    this.event(_EVENTS.startAudio)
                    break
                case mediaType.video:
                    this.event(_EVENTS.startVideo)
                    break
                default:
                    return
                    break;
            }
            
        } catch (err) {
          //  Modals.errorNotification(err + ": " + type );
          console.log ("ERROR", err, type);
            if (type == "videoType") {
                videoallowed = false;
                SessionData.errorcheck.video = true;
            }
            if (type == "audioType") {
                audioallowed = false;
                SessionData.errorcheck.audio = true;
                console.log ("BAD AUDIO");
            }

            let userjoined = {
                producer_id: "nomedia",
                producer_name: this.name,
                producer_socket_id: SessionData.socket.id
            }
            SessionData.errorcheck.socket = true;
            Utils.createNoStreamInfo(userjoined, videoallowed, audioallowed);

            
        }
        
        
    }




    
    async consume(producer_id, data) {
        
        //let info = await roomInfo()

        data.forEach( d => {
            let u = {
                user_id: d.producer_socket_id,
                user_name: d.producer_name
            }

            let found = SessionData.users.some(el => el.user_id === u.user_id);            
            if (!found) {
                $('#cursors').append("<div id='cursor-"+u.user_id+"' class='cursor'><div class='username'>" + u.user_name+"</div>")
                SessionData.users.push(u);
            }
                        
        })

        
        m.redraw();
        
        
        // 1. create an empty element  with the socket id of data matching the producer_id
        let userjoined = data.filter(user => user.producer_id == producer_id)[0];
        // 2. if this element exists don't recreate it
        if (!$('#videocontainer_'+userjoined.producer_socket_id).length) {
            Utils.createStreamContainer(userjoined);
        }  

        this.getConsumeStream(producer_id).then(function ({
            consumer,
            stream,
            kind
        }) {

            this.consumers.set(consumer.id, consumer)
           
            if (kind === 'video') { 
                this.createUserVideoElement(stream, userjoined.producer_socket_id, consumer.id);
            } else {
                this.createUserAudioElement(stream, userjoined.producer_socket_id, consumer.id);
            }
            // MS remove trackended
            consumer.on('trackended', function () {
                console.log ("++ CONSUMER TRACKENDED", consumer);
               // this.removeConsumer(consumer.id)
            }.bind(this))
            consumer.on('transportclose', function () {
                this.removeConsumer(consumer.id)
            }.bind(this))


        }.bind(this))
    }

    


    createUserAudioElement (stream, userID, consumerid) {
        let elem = document.createElement('audio');
        elem.srcObject = stream;
        elem.id = consumerid;
        elem.playsinline = false;
        elem.autoplay = true;
        $('.videocontainer_'+userID).append(elem)
        
    }


    createUserVideoElement (stream, userID, consumerid) {
        // create video element

        let elem = document.createElement('video');
        elem.srcObject = stream;
        elem.id = consumerid;
        elem.playsinline = false;
        elem.autoplay = true;

        
        
        elem.className = "vid";
        $('.videocontainer_'+userID).append(elem); // add to bottom of list


    
        // 1. get container height
        let h = $('#uservideos').height() - 25;
        // 2. get total amount of video containers
        let c = $('#uservideos .videocontainer').length;
        let vh  = $('#uservideos .videocontainer').height();
        // 3. if total height of all videos > container height, adjust width/height to fit screen
        let newheight;
        if ( c*vh > h) {
            newheight = h / c;
            let newwidth = newheight * 4/3;
            $('#uservideos .videocontainer video').attr("height", newheight);
            $('#uservideos .videocontainer video').attr("width", newwidth);
            $('.window').css("left", newwidth+10+"px");
        } else {
            $('#uservideos .videocontainer video').attr("width", 175);
            $('#uservideos .videocontainer video').attr("height", 175*3/4);
            $('.window').css("left", "185px");
        }
    }

    async getConsumeStream(producerId) {
        const {
            rtpCapabilities
        } = this.device
        const data = await this.socket.request('consume', {
            rtpCapabilities,
            consumerTransportId: this.consumerTransport.id, // might be 
            producerId
        });
        
        const {
            id,
            kind,
            rtpParameters,
        } = data;

        let codecOptions = {};
        const consumer = await this.consumerTransport.consume({
            id,
            producerId,
            kind,
            rtpParameters,
            codecOptions,
        })
        const stream = new MediaStream();
        stream.addTrack(consumer.track);

        
        return {
            consumer,
            stream,
            kind
        }
    }

    closeProducer(type) {
        if (!this.producerLabel.has(type)) {
            console.log('there is no producer for this type ' + type)
            return
        }
        let producer_id = this.producerLabel.get(type)
        console.log(producer_id)
        
        this.socket.emit('producerClosed', {
            producer_id
        })
        this.producers.get(producer_id).close()
        this.producers.delete(producer_id)
        this.producerLabel.delete(type)

        if (type !== mediaType.audio) {
            let elem = document.getElementById(producer_id)
            elem.srcObject.getTracks().forEach(function (track) {
                track.stop()
            })
        }
        $('#'+SessionData.socket.id).remove();
        // remove user cursor when they leave the game
        let cursorEl = document.getElementById("cursor-" + SessionData.socket.id);
        if (cursorEl != null) cursorEl.remove();
        

        switch (type) {
            case mediaType.audio:
                this.event(_EVENTS.stopAudio)
                break
            case mediaType.video:
                this.event(_EVENTS.stopVideo)
                break
            default:
                return
                break;
        }

    }

    pauseProducer(type) {
        if (!this.producerLabel.has(type)) {
            console.log('there is no producer for this type ' + type)
            return
        }
        let producer_id = this.producerLabel.get(type)
        this.producers.get(producer_id).pause()

        if (type == mediaType.audio) this.pauseAudio = !this.pauseAudio;
        if (type == mediaType.video) this.pauseVideo = !this.pauseVideo;
        console.log ("PAUSE", type, mediaType, this.pauseAudio, this.pauseVideo);
    }


    resumeProducer(type) {
        if (!this.producerLabel.has(type)) {
            console.log('there is no producer for this type ' + type)
            return
        }
        let producer_id = this.producerLabel.get(type);
        this.producers.get(producer_id).resume();

        
        if (type == mediaType.audio) this.pauseAudio = !this.pauseAudio;
        if (type == mediaType.video) this.pauseVideo = !this.pauseVideo;
        console.log ("RESUME", type, mediaType, this.pauseAudio, this.pauseVideo);
    }

    removeConsumer(consumer_id) {
        
        let elem = document.getElementById(consumer_id)
        
        if (elem != null) elem.srcObject.getTracks().forEach(function (track) {
            track.stop()
        })
        
        let socketID = $('#'+consumer_id).closest(".videocontainer").data("socket");
        if (socketID != undefined) {
            SessionData.totalUsers = SessionData.totalUsers-1;
        }
        
        console.log ("%c user leaving with socket: " + socketID, 'background: red; color: #000');
        // remove user cursor when they leave the game
        let cursorEl = document.getElementById("cursor-" + socketID);
        if (cursorEl != null) cursorEl.remove();
        
        
        if (SessionData.moderator == socketID) {
            SessionData.moderator = "";
            Modals.persistentNotification(Database.getUIJSON().moderator_left_game);
            Config.stepForModerator = SessionData.currentGameStep; // allow to set a moderator for this step
          }
        $('#'+consumer_id).closest('.videocontainer').remove();

        $('#'+consumer_id).remove();
        this.consumers.delete(consumer_id)
    
        SessionData.users = SessionData.users.filter(user => user.user_id !== socketID);
    }

    exit(offline = false) {

        let clean = function () {
            this._isOpen = false
            if (this.consumerTransport) this.consumerTransport.close()
            if (this.producerTransport) this.producerTransport.close()
            this.socket.off('disconnect')
            this.socket.off('newProducers')
            this.socket.off('consumerClosed')
        }.bind(this)

        if (!offline) {
            this.socket.request('exitRoom').then(e => console.log(e)).catch(e => console.warn(e)).finally(function () {
                clean()
            }.bind(this))
        } else {
            clean()
        }

        this.event(_EVENTS.exitRoom)

    }

    ///////  HELPERS //////////

    async roomInfo() {
        let info = await this.socket.request('getMyRoomInfo')
        return info
    }


    async transportStats () {
        if (!this.producerTransport) return;
        const data = await this.socket.request('getTransportStats', {transport_id: this.producerTransport.id});
        return data;
    }

    static get mediaType() {
        return mediaType
    }


    event(evt) {
        if (this.eventListeners.has(evt)) {
            this.eventListeners.get(evt).forEach(callback => callback())
        }
    }

    on(evt, callback) {
        this.eventListeners.get(evt).push(callback)
    }




    //////// GETTERS ////////

    isOpen() {
        return this._isOpen
    }
  
    static get EVENTS() {
        return _EVENTS
    }
}

export default RoomClient;