import {fabric} from "fabric";
import m from "mithril";
import Database from "./database.class";
import SessionData from "./session.class";
import boardLayout from "./boardLayout.class";
import Utils from './utils.class';

import videoButtons from '../components/videoButtons.ui';
import $ from 'jquery';
import Config from "../config";
import toolbarHelper from "./toolbarHelper.class";



let k = 0;
let _this;
let objList = [];
let selection = false;
let isDragging, lastPosX, lastPosY, sc;
class Board {


    constructor() {
        _this = this;

        

    }

    // mithril hook, after DOM is created
    oncreate() {
        if (SessionData.canvas != "") return; // if we reconnect don't recreate the canvas
        
        SessionData.canvas = new fabric.Canvas('c', {selection: false, preserveObjectStacking:true,hasControls:false, backgroundColor : (m.route.param().version == 2) ? 'rgb(240,237,246)' : "rgb(230,233,222)"});
        
        SessionData.canvas.defaultCursor = 'grab';

        sc = this.__canvas = new fabric.StaticCanvas('sc');

        fabric.Group.prototype.hasControls = false;
        this.canvasListeners();
        this.resizeCanvas();
        if (m.route.param().version != 2) this.drawStaticCanvasGrid(); 
    }
 
    drawStaticCanvasGrid () {       
        toolbarHelper.createImage("./images/bg.png").then((oimg) => {
            let w = 1007;
            let h = 800;
            oimg.scaleToWidth(w);
            
            SessionData.canvas.setBackgroundImage(oimg, SessionData.canvas.renderAll.bind(SessionData.canvas), {
                width: w,
                height: h,
                top:  $(window).height()/2 - h/2, 
                left:$(window).width()/2 - w/2,
             });
        });
        
    }


    // setup canvas listeners
    canvasListeners() {
        SessionData.canvas.on('object:moving', this.emitObjectModifying);
        SessionData.canvas.on('object:scaling', this.emitObjectModifying);
        SessionData.canvas.on('object:rotating', this.emitObjectModifying);

        SessionData.socket.on('object:modifying', this.onObjectModifying);
        SessionData.socket.on ("assignEvaluationTag", (data) => {
            let obj = Utils.getObjectById(data.id);
            toolbarHelper.saveTag(obj, data.tag);
        } )
        SessionData.socket.on ("updateEvaluationTag", (data) => {
            let obj = Utils.getObjectById(data.id);
            if (obj != undefined) obj.tags[data.index].scale = data.value; // update scale if we're moving the range           
        } )

        // show user mouse
        document.onmousemove = (e) => {
            this.getMousePos(e);
            this.showTooltip(e);
        };
        window.addEventListener("resize", this.resizeCanvas);

        SessionData.canvas.on('mouse:wheel', this.canvaszoom);
        SessionData.canvas.on("mouse:down", this.canvasdrag);
        SessionData.canvas.on("mouse:move", this.canvasmove);
        SessionData.canvas.on("mouse:up", this.canvasup);

        SessionData.canvas.on("selection:created", (e) => {
            e.redraw = false;
            selection = true;
            if (e.selected.length > 1) return;

            // "ankommen" has a pulsate animation. stop it when selected
            if (e.selected[0].interval != undefined) {
                clearInterval(e.selected[0].interval);
                e.selected[0].scaleX = e.selected[0].scaleY = 1;
            }
            if (!SessionData.evaluation) this.objectSelected(e); 
            if (SessionData.evaluation) toolbarHelper.assignEvaluationTag(e.target);
        })

        SessionData.canvas.on("selection:cleared", (e) => {
            e.redraw = false;
            selection = false;
            $('.deletebtn').addClass("hide");
            $('.grouptools').addClass("hide");
            $('#slider').fadeOut();
        })

        SessionData.canvas.on("selection:updated", (e) => {
            selection = true;
            e.redraw = false;
            if (e.selected.length > 1) return;
            if (!SessionData.evaluation) this.objectSelectionUpdated(e);
            if (SessionData.evaluation) toolbarHelper.assignEvaluationTag(e.target);
        })


    } 

 
    
    objectSelected (e) {
        if (SessionData.canvas.getActiveObject().cardtype == Config.cardtypes.island) {
            setTimeout(() => {
                boardLayout.positionGroupBar(e.target);
                 $(".grouptools").removeClass("hide");    
            }, 250);
            
            $('.deletebtn').addClass("hide");
        } else {
            $(".grouptools").addClass("hide");
            boardLayout.positionDeleteBtn(e.target); 
        }
    }

    objectSelectionUpdated (e) {
        if (SessionData.canvas.getActiveObject().cardtype == Config.cardtypes.island) {
            setTimeout(() => {
                boardLayout.positionGroupBar(e.target);
                 $(".grouptools").removeClass("hide");    
            }, 250);
            $('.deletebtn').addClass("hide");
        } else {
            $(".grouptools").addClass("hide");
            boardLayout.positionDeleteBtn(e.target); 
        }
    }

    canvasup(opt) {
        // if we've shown connection targets, remove all their divs
        $('.connectiontarget').remove();
        // on mouse up we want to recalculate new interaction
        // for all objects, so we call setViewportTransform
        SessionData.canvas.setViewportTransform(SessionData.canvas.viewportTransform);
        isDragging = false;

    }
    canvasmove(opt) {
        if (isDragging) {
            var e = opt.e;
            var vpt = SessionData.canvas.viewportTransform;
            vpt[4] += e.clientX - lastPosX;
            vpt[5] += e.clientY - lastPosY;
            SessionData.canvas.requestRenderAll();
            lastPosX = e.clientX;
            lastPosY = e.clientY;
        }
    }
    canvasdrag(opt) {
        var evt = opt.e;
        // if dragging the canvas with ALT key pressed, pan the canvas 
        if (selection == false) {
            $('.deletebtn').addClass("hide");
            $('.grouptools').addClass("hide");
            isDragging = true;
 
            lastPosX = evt.clientX;
            lastPosY = evt.clientY;
        }
        //_this.highlightLineTargets(opt);
        
    }


    highlightLineTargets (opt) {
        if (opt.target == undefined) return;
        //if dragging a line controller, show the possible targets
        
        if (opt.target.cardtype == Config.cardtypes.linecontroller) {
            
            for (var i = 0; i < SessionData.objectsOnCanvas.length; i++) {
                if (SessionData.objectsOnCanvas[i].cardtype == Config.cardtypes.island) {
                    // don't show the connector indicator on the selected island
                    if (SessionData.objectsOnCanvas[i].id != opt.target.originIsland) {
                        var targetDiv = $("<div>", {"class": "connectiontarget"});
                        SessionData.canvas.renderAll();
                        SessionData.canvas.calcOffset();
                        let g = SessionData.objectsOnCanvas[i];
                        setTimeout(() => {
                            SessionData.canvas.renderAll();
                            SessionData.canvas.calcOffset();
                            
                            
                            var absCoords = {
                                left: g.aCoords.tl.x,
                                top: g.aCoords.tl.y
                            };
                            targetDiv.css("left", absCoords.left + 'px');
                            targetDiv.css("top", absCoords.top + 'px');
                            
                            $('#app').append(targetDiv);
                        }, 500);
                        
                    }
                    
                    
                    
                }
            }
        }
    }
    canvaszoom(opt) {
        SessionData.canvas.discardActiveObject().renderAll();

        $('.deletebtn').addClass("hide");
        $('.grouptools').addClass("hide");
        let delta = 0;

        // -------------------------------
        // WHEEL RESOLUTION
        let wheelDelta = opt.e.wheelDelta;
        let deltaY = opt.e.deltaY;

        // CHROME WIN/MAC | SAFARI 7 MAC | OPERA WIN/MAC | EDGE
        if (wheelDelta) delta = -wheelDelta / 120;

        // FIREFOX WIN / MAC | IE
        if (deltaY) deltaY > 0 ? delta = 1 : delta = -1;

        // -------------------------------
        // let pointer = SessionData.canvas.getPointer(opt.e);
        let zoom = SessionData.canvas.getZoom();
        zoom = zoom - delta / 50;
        // limit zoom in
        if (zoom > 1.5) zoom = 1.5;
        // limit zoom out
        if (zoom < 0.5) zoom = 0.5;

        //SessionData.canvas.zoomToPoint(new fabric.Point(SessionData.canvas.width / 2, SessionData.canvas.height / 2), zoom);
        SessionData.canvas.zoomToPoint({
            x: opt.e.offsetX,
            y: opt.e.offsetY
        }, zoom);
        opt.e.preventDefault();
        opt.e.stopPropagation();

        SessionData.canvas.renderAll();
        SessionData.canvas.calcOffset();
    }




    onObjectModifying(data) {
        var obj = Utils.getObjectById(data.id);
        
        if (typeof obj !== 'undefined') {
            
            // don't show the delete btn if someone moves the object you've selected
            if (SessionData.canvas.getActiveObject() != undefined) {
                if (obj.id == SessionData.canvas.getActiveObject().id) $('.deletebtn').addClass("hide");
            }
            
            
            let _left = (data.left * window.innerWidth) / data.browserWidth;
            let _top = (data.top * window.innerHeight) / data.browserHeight;
            obj.left = data.left;
            obj.top = data.top;
            obj.scaleX = data.scaleX;
            obj.scaleY = data.scaleY;
            obj.angle = data.angle;

            // $('.deletebtn').addClass("hide");
            // $('.grouptools').addClass("hide");


            // "ankommen" has a pulsate animation. stop it when selected
            if (obj.interval != undefined) {
                clearInterval(obj.interval);
                obj.scaleX = obj.scaleY = 1;
            }
            // group.line connect groups done
            _this.moveLines(obj);

            obj.setCoords();
            SessionData.canvas.renderAll();
        }

    };

    emitObjectModifying(event) {
        let activeObject = event.target;
        
        
        activeObject.opacity = 1;
        // group.line connect groups done

        _this.moveLines(activeObject);
        
        event.target.set({
            left: event.target.left,//Math.round(event.target.left / grid) * grid,
            top: event.target.top//Math.round(event.target.top / grid) * grid
        });
       
       
       
       $('.deletebtn').addClass("hide");// don't show the delete btn while moving
       $('.grouptools').addClass("hide"); 
       
        SessionData.socket.emit('object:modifying', {
            id: activeObject.id,
            left: activeObject.left, 
            top: activeObject.top,
            scaleX: activeObject.scaleX,
            scaleY: activeObject.scaleY,
            angle: activeObject.angle,
            username: SessionData.Name,
            browserWidth: window.innerWidth,
            browserHeight: window.innerHeight,
            room: SessionData.roomname
        });
    }

    

    moveLines (obj) {
        // don't drag line
        if (obj.cardtype == Config.cardtypes.line) return;
        // move line together with the group
        if (obj.lineFrom != undefined && obj.type == "group") {
            for (var j = 0; j<obj.lineFrom.length; j++) {
                let lineobj = Utils.getObjectById(obj.lineFrom[j]);
                if (lineobj != undefined) lineobj.set({ 'x1': obj.left + obj.width/2, 'y1': obj.top + obj.height/2 }); // if we're moving a group with a line attached to it
            }
        }

        if (obj.lineTo != undefined && obj.type == "group") {
            for (var j = 0; j<obj.lineTo.length; j++) {
                let lineobj = Utils.getObjectById(obj.lineTo[j]);
                if (lineobj != undefined) lineobj.set({ 'x2': obj.left + obj.width/2, 'y2': obj.top + obj.height/2 }); // if we're moving a group with a line attached to it
            }
        }

        // move line together with the line controller
        if (obj.type == "circle" && obj.line != undefined) {
            for (var j = 0; j<obj.line.length; j++) {
                let lineobj = Utils.getObjectById(obj.line[j]);
                if (lineobj != undefined) lineobj.set({ 'x2': obj.left + obj.width/2, 'y2': obj.top + obj.height/2 }); // if we're moving a line controller with a line attached to it
            }
        }
    }
 
    showTooltip (e) {
        if (document.getElementById("tooltip") == null) return;
        
        let attr = $(e.target).attr('tooltip');

        if (typeof attr !== 'undefined' && attr !== false) {
            let x = e.clientX;
            let y = e.clientY;
            document.getElementById("tooltip").style.left = x + "px";
            document.getElementById("tooltip").style.top = y + "px";
            document.getElementById("tooltip").style.display = "block";
            document.getElementById("tooltip").style.opacity = 1;
            

            document.getElementById("tooltip").style.marginLeft = $(e.target).attr('distx') + "px";
            document.getElementById("tooltip").style.marginTop = $(e.target).attr('disty') + "px";
            $('#tooltip span').html(attr);
        } else {
            document.getElementById("tooltip").style.display = "none";
            document.getElementById("tooltip").style.opacity = 0;
        }


        if ( $(e.target).parent().hasClass("used-card")) {
            
            let x = e.clientX;
            let y = e.clientY;
            document.getElementById("tooltip").style.left = x + "px";
            document.getElementById("tooltip").style.top = y + "px";
            document.getElementById("tooltip").style.display = "block";
            document.getElementById("tooltip").style.opacity = 1;
            document.getElementById("tooltip").style.marginLeft = "-25px";
            document.getElementById("tooltip").style.marginTop = "-25px";
            $('#tooltip span').html(Database.getUIJSON().cardOnBoardTxt);

        }
    }
    getMousePos(e) {
        // if (e.altKey === true) {
        //     console.log ("MOUSE POS", e.clientX, e.clientY);
        //     console.log ("CANVAS VPT", SessionData.canvas.viewportTransform)
        // }
        $('#mouseprogress').css({'top': e.clientY + document.body.scrollTop -10 ,'left': e.clientX + document.body.scrollLeft+10}); 

      //  console.log (e.clientX, e.clientY);
        let data = {
            mouseposX: e.clientX + document.body.scrollLeft,
            mouseposY: e.clientY + document.body.scrollTop,
            user: SessionData.username,
            room: SessionData.roomname,
            browserWidth: window.innerWidth,
            browserHeight: window.innerHeight,
            id: SessionData.socket.id
        }

        SessionData.socket.emit("moving", data);
    }


    resizeCanvas() {
        let minWidth = 480;
        let minHeight = 480;

        let containerWidth = window.innerWidth > minWidth ? window.innerWidth : minWidth;
        let containerHeight = window.innerHeight > minHeight ? window.innerHeight : minHeight;


        SessionData.canvas.setDimensions({
            width: containerWidth,
            height: containerHeight
        });
        
        SessionData.canvas.calcOffset();
        SessionData.canvas.renderAll();

    }

    // basic mithril view, draw canvas
    view() {
        return [
            m("div", {id:'slider'}, [
                m("input", {type:"range", id:"rangeInput", min:"1", max:10, value:"2", id:"range"
                }),
                m("output", {id:"slideramount", name:"amount"},0)
            ]),
            m("canvas", {
                id: "c"
            }),
            m("div", {id: "cursors"}), 
            m("footer", [
                m("ul", [
                    m("li", [
                        m("a", {id:"imprint",href:"https://www.kulturstiftung-des-bundes.de/hochdrei_visionenspiel", target:"_blank",tabindex:5, "aria-label":Database.getUIJSON().footerImprint}, Database.getUIJSON().footerImprint),
                    ]),
                    m("li", [
                        m("a", {id:"colors",href:"#", tabindex:4, "aria-label":Database.getUIJSON().footerInvert, onclick:(e) => {
                            e.preventDefault();
                            $("body").toggleClass("inverted");
                            $("video").toggleClass("inverted");
                        }}, Database.getUIJSON().footerInvert),
                    ]),
                    m("li", [
                        m("a", {id:"groups",href:"#", tabindex:3, "aria-label":Database.getUIJSON().footerGroups, onclick:(e) => {
                            e.preventDefault();
                            let label = Utils.GroupsToString();
                            
                            if ('speechSynthesis' in window) {
                                // Speech Synthesis supported 🎉
                                let msg = new SpeechSynthesisUtterance(label);
                                msg.onend = function (event) {
                                    console.log('SpeechSynthesisUtterance.onend');
                                }
                                msg.onerror = function (event) {
                                    console.error('SpeechSynthesisUtterance.onerror');
                                }
                                let synth = window.speechSynthesis;
                                msg.lang = 'de';

                                if (!synth.speaking) synth.speak(msg);
                                
                            }
                           // $("a#groups").attr("tooltip", label);
                            $("a#groups").attr("aria-label", label);
                        }}, Database.getUIJSON().footerGroups),
                    ]),
                    m("li", [
                        m(videoButtons, {audioX:0, videoX:0, audioY: -25, videoY: -25}),
                    ])
                ]),
                
                

            ])
        ];

    }
}
export default Board;