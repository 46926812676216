import m from "mithril";

import Splash from "./views/splash.class";
import Main from "./views/main.class";

const root = document.querySelector("#app");

// redirect to https if we're not working on localhost
//if ( process.env.NODE_ENV != "development") {
  if(location.href.substr(0,5) !== 'https') location.href = 'https' + location.href.substr(4, location.href.length - 4)
//}


  console.log ("version:082023")
// mithril routes
m.route(root, "/splash", {
  "/splash": Splash,
  "/main": Main,
})