import m from "mithril";
import Modals from '../views/modals.class';
import SessionData from './session.class';
import Swal from 'sweetalert2';
import Database from '../models/database.class';
import $ from 'jquery';
import { fabric } from "fabric";
import Config from '../config';
import videoButtons from '../components/videoButtons.ui';
import alertify from 'alertifyjs';

let currentProgressInterval, currentProgressID;
class Utils {


    static showModal(type) {

        switch (type) {
            case "roomselection":
                Modals.roomselection();
                break;
            case "confirmstart":
                Modals.confirmstart();
                break;
            default:

        }

    }


    static joinRoom(roomname, username) {
        SessionData.roomname = roomname;
        SessionData.username = username;

        // show a short notification, that the user is joining the room
        let Toast = Swal.mixin({
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: Config.gameTimer,
            timerProgressBar: true
        })

        let data = {
            roomname: SessionData.roomname,
            username: SessionData.username
        };
        Toast.fire({
            icon: 'success',
            title: Utils.applyTemplate(Database.getUIJSON().modal_joined_notification, data),
        }).then(() => {

            let c = Utils.getParams(window.location.href);
            if (c.version == undefined) c.version = 1;
            m.route.set("/main", {
                key: Date.now(),
                version: c.version,
                roomname: SessionData.roomname,
                username: SessionData.username
            })
        });
    }

    static crossBrowserUserMedia() {
        if (navigator.mediaDevices === undefined) {
            navigator.mediaDevices = {};
        }

        // Some browsers partially implement mediaDevices. We can't just assign an object
        // with getUserMedia as it would overwrite existing properties.
        // Here, we will just add the getUserMedia property if it's missing.
        if (navigator.mediaDevices.getUserMedia === undefined) {
            navigator.mediaDevices.getUserMedia = function (constraints) {

                // First get ahold of the legacy getUserMedia, if present
                var getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

                // Some browsers just don't implement it - return a rejected promise with an error
                // to keep a consistent interface
                if (!getUserMedia) {
                    return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
                }

                // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
                return new Promise(function (resolve, reject) {
                    getUserMedia.call(navigator, constraints, resolve, reject);
                });
            }
        }


        // 
        // // firefox doesn't support custom video constraints, we're using ideal instead of min/max
        // if(navigator.userAgent.toLowerCase().indexOf('firefox') > -1 && videoallowed){
        //   mediaconstraints = {video:true, audio:true};
        // }
    }


    static getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    // returns a canvas objects. Remember to push all canvas objects to the objectsOnCanvas object
    static getObjectById(id) {
        for (var i = 0; i < SessionData.objectsOnCanvas.length; i++) {
            if (SessionData.objectsOnCanvas[i].id === id)
                return SessionData.objectsOnCanvas[i];
        }
        
    };

    // remove shadows from all objects on canvas
    static clearHighlights() {
        
        $('#slider').fadeOut();
        for (var i = 0; i < SessionData.objectsOnCanvas.length; i++) {
            SessionData.objectsOnCanvas[i].shadow = null;
            if(SessionData.objectsOnCanvas[i].cardtype == Config.cardtypes.activity) SessionData.objectsOnCanvas[i]._objects[0].set({ shadow: null }); //highlight object as evaluated
            if(SessionData.objectsOnCanvas[i].cardtype == Config.cardtypes.island) {
                SessionData.objectsOnCanvas[i].set({ shadow: null }); //highlight object as evaluated
                SessionData.objectsOnCanvas[i]._objects.forEach (item => {
                    if(item.cardtype == Config.cardtypes.activity) item._objects[0].set({ shadow: null }); //highlight object as evaluated
                })
            }
        }
        SessionData.canvas.renderAll();
    };

    
    static removeObjectById(id) {
        for (var i = 0; i < SessionData.objectsOnCanvas.length; i++) {
            if (SessionData.objectsOnCanvas[i].id === id) {
                SessionData.canvas.remove(SessionData.objectsOnCanvas[i]);
                SessionData.objectsOnCanvas.splice(i, 1);
                SessionData.canvas.renderAll();
                return SessionData.objectsOnCanvas[i];
            }
        }
    }

    static formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';
    
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    
        const i = Math.floor(Math.log(bytes) / Math.log(k));
    
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
    
    static setModerator(socketid) {
        // set all moderators to false
        alertify.dismissAll();
        for (var i = 0; i < SessionData.users.length; i++) {
            SessionData.users[i].moderator = false;
        }


        let index = SessionData.users.findIndex((obj => obj.user_id == socketid)); // get the user object we should edit
        if (index == -1) {
            SessionData.users.push({
                user_id: socketid,
                user_name: $('.user_'+socketid).html(),
                moderator: true
            });
            index = SessionData.users.findIndex((obj => obj.user_id == socketid));
        } else {
            SessionData.users[index].moderator = true; // set selected moderator to true
            SessionData.moderator = socketid;
        }

        // TODO swal might not be there any more at last step
        if (SessionData.users[index].user_id == SessionData.socketID) Swal.enableButtons();
        if (SessionData.users[index].user_id != SessionData.socketID) Swal.disableButtons();

        setTimeout(() => {
            $(".videocontainer").removeClass("moderator");
            $(".videocontainer_" + socketid).addClass("moderator");
            m.redraw();    
        }, 500);
        
    }

    static emitModerator(socketid) {
        SessionData.socket.emit("setmoderator", {
            moderator: socketid,
            username: SessionData.username,
            room: SessionData.roomname
        });
    }

    


    static formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';
    
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    
        const i = Math.floor(Math.log(bytes) / Math.log(k));
    
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    
    static updateProgressbarDuration(extraminutes) {
        let minutesinms = extraminutes * 60 * 1000;
        let totalDuration = Database.getGameStepsJSON().steps["step" + SessionData.currentGameStep].duration_inminutes;
        let newDuration = totalDuration + extraminutes;
        let nd_in_ms = newDuration * 60 * 1000;
        
        
        
        let heightincrease = (100 * extraminutes*60*1000) / (totalDuration*60*1000);
        
        
        let elem = document.getElementById("currentprogress" + SessionData.currentGameStep);
        let currentheight = Number(elem.style.height.replace('%', ''));
        let newheight = (heightincrease+currentheight >= 100) ? "100" : heightincrease+currentheight;
   
        elem.style.height = newheight + "%";
    }



    static initProgressbar(id, duration, joker) {
        if (id == currentProgressID) return;
        
        currentProgressID = id;
        clearInterval(currentProgressInterval); // clear the interval of any previous progressbars
        let twominutesnotified = false; // notifier flag
        let currentStepDuration = Database.getGameStepsJSON().steps["step" + SessionData.currentGameStep].duration_inminutes; // notify only if the step is longer than 2 minutes already
        
        
        
        let heightdecrease = (100*1000)/duration; // percentage how the height of the progress bar should decrease
        let elem = document.getElementById("currentprogress" + SessionData.currentGameStep);

        $('#'+id).css("opacity", 1);
        
        let height = 100;
        elem.style.height = height+"%";
        currentProgressInterval = setInterval(frame, 1000);
        
        function frame() {
            if (height <= 0) {
                clearInterval(currentProgressInterval);
                let content = Database.getUIJSON().step_time_over;
                Modals.simpleNotification(content);
                $('.remainingtime').html("00:00 Minuten"); 
                elem.style.height = "0%";
            } else {  
                height = Number(elem.style.height.replace('%', '')) - heightdecrease;

                elem.style.height = height + "%";


                let remainingtime_in_minutes = height/100 * duration / 1000 / 60;
                let remainingtime_in_seconds = height/100 * duration / 1000;
                let numminutes = Math.floor((((remainingtime_in_seconds % 31536000) % 86400) % 3600) / 60);
                let numseconds = Math.floor ((((remainingtime_in_seconds % 31536000) % 86400) % 3600) % 60);
                
                if (numminutes < 0) numminutes = 0;
                if (numseconds < 0) numseconds = 0;
                if (numseconds < 10) numseconds = "0" + numseconds;
                if (numminutes < 10) numminutes = "0" + numminutes;
                $('.remainingtime').html( numminutes + ":" + numseconds + " Minuten");


                if (remainingtime_in_minutes <= 2 && !twominutesnotified && currentStepDuration > 2) {
                    twominutesnotified = true;
                    let content = Database.getUIJSON().two_minutes_left;
                    Modals.simpleNotification(content);
                }
            }
        }

    }

    static resetProgressbar () {
        clearInterval(currentProgressInterval); // clear the interval of any previous progressbars
    }

    static updateTotalProgressbar(extratime) {
         if (extratime == undefined) extratime = 0;
         let percentage = Database.getGameStepsJSON().steps["step" + SessionData.currentGameStep].duration_percentage;

         $('#totalprogress').css("width", percentage +"%");
         $('#totalprogressbar-text').css("left", "calc(" + percentage +"% - 40px)");
         if (Math.round(percentage) > 0) $('#totalprogressbar-text').html(Math.round(percentage) + ' %');
    }


    static initTotalProgressbar(id, duration, start) {
        let widthincrease = (100*1000)/duration;
        let elem = document.getElementById("totalprogress");
        let width = widthincrease;
        width = Number(elem.style.width.replace('%', '')) + widthincrease;
                  
        $('#totalprogressbar-text').css("left", "calc(" + width +"% - 40px)");
        $('#totalprogressbar-text').html(Math.round(width) + ' %');
                  
        elem.style.width = width + "%";
    }



    static getParams(url) {
        var params = {};
        var parser = document.createElement('a');
        parser.href = url;
        var query = parser.search.substring(1);
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            params[pair[0]] = decodeURIComponent(pair[1]);
        }
        return params;
    };


    static getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }


    // move the cursor of other users across the screen
    static getCursorElement(data) {
        // data.id, data.user, data.mouseposX, data.mouseposY
        let elementId = 'cursor-' + data.id;

        let el = document.getElementById(elementId);
        if (!el) return;
        
        let _left = (data.mouseposX * window.innerWidth) / data.browserWidth;
        let _top = (data.mouseposY * window.innerHeight) / data.browserHeight;
        el.style.left = data.mouseposX + "px";
        el.style.top = data.mouseposY + "px";
    }


    static getAbsoluteCoords (object) {
        
        SessionData.canvas.renderAll();
        SessionData.canvas.calcOffset();
        var absCoords = {
            left: object.oCoords.tl.x,
            top: object.oCoords.tl.y
        };
        
        return {
            left: absCoords.left,
            top: absCoords.top,
            width: object.scaleX * object.width * SessionData.canvas.getZoom(),
            height: object.scaleY * object.height * SessionData.canvas.getZoom()
        };
    }
    // method to draw a hexagon
    static regularPolygonPoints(sideCount, radius) {
        let sweep = Math.PI * 2 / sideCount;
        let cx = radius;
        let cy = radius;
        let points = [];
        for (let i = 0; i < sideCount; i++) {
            var x = cx + radius * Math.cos(i * sweep);
            var y = cy + radius * Math.sin(i * sweep);
            points.push({
                x: x,
                y: y
            });
        }
        return (points);
    }


    // use this to apply variables to a template
    // usage Utils.applyTemplate( "the name of the room is {{roomname}}", {roomname: customname})
    static applyTemplate(template, data) {
        return Object.keys(data).reduce(
            Utils.reduceTemplate.bind({
                data
            }),
            template
        );
    }
    static escapeRegExp(input) {
        return (input || '').replace(/([.*+?^${}()|\[\]\/\\])/g, '\\$1');
    }
    static reduceTemplate(template, key) {
        return template.replace(
            new RegExp('\{\{\\s*' + Utils.escapeRegExp(key) + '\\s*\}\}', 'g'),
            this.data[key]
        );
    }


    static removeKeys(obj, keys) {
        for (var prop in obj) {
            if(obj.hasOwnProperty(prop)) {
                switch(typeof(obj[prop])) {
                    case 'object':
                        if(keys.indexOf(prop) > -1) {
                            delete obj[prop];
                        } else {
                            Utils.removeKeys(obj[prop], keys);
                        }
                        break;
                  default:
                        if(keys.indexOf(prop) > -1) {
                            delete obj[prop];
                        }
                        break;
                }
            }
        }
    }


    static createNoStreamInfo (_userjoined, _videoallowed, _audioallowed) {
        
        console.log ("create no stream info", _userjoined.producer_socket_id, _videoallowed, _audioallowed )
        
        if ( $('#videocontainer_'+_userjoined.producer_socket_id + " .unavailable").length <= 0 ) { // IF THE UNAVAILABLE DIV DOESN'T EXIST
            Utils.createStreamContainer(_userjoined);
            $("#videocontainer_"+_userjoined.producer_socket_id).append("<div class='unavailable'></div>");
            SessionData.totalUsers += 1;
        }      
    }
    
    static createStreamContainer (userjoined) {
        
        if ( $('#videocontainer_'+userjoined.producer_socket_id).length ) return;
        
        let videocontainer = document.createElement("div");
        videocontainer.className = "videocontainer videocontainer_" + userjoined.producer_socket_id + " video_username-" + userjoined.producer_name;
        videocontainer.id = "videocontainer_" + userjoined.producer_socket_id;
        videocontainer.setAttribute("data-socket", userjoined.producer_socket_id);
        videocontainer.setAttribute("data-username", userjoined.producer_name);

        if (userjoined.producer_socket_id == SessionData.socketID) {
            $('#uservideos').append(videocontainer);
            if (Config.videocall) m.mount(document.getElementById("videocontainer_"+SessionData.socket.id), {view: function () {return m(videoButtons, {audioX:175, videoX:125 })}} );
        } else {
            $('#uservideos').prepend(videocontainer);
        }
        SessionData.totalUsers = SessionData.totalUsers+ 1;

        // create username
        let username = document.createElement("div");
        username.className = "username " + "user_"+userjoined.producer_socket_id;
        videocontainer.append(username);
        $(".user_"+userjoined.producer_socket_id).html(userjoined.producer_name);
        
        // create overlay
        let overlay = document.createElement("div");
        overlay.className = "overlay";
        videocontainer.append(overlay);
        $(".videocontainer_"+userjoined.producer_socket_id + " .overlay").html(Database.getUIJSON().set_moderator);

        $('.videocontainer_'+userjoined.producer_socket_id).click( () => {
            if (SessionData.currentGameStep != Config.stepForModerator) return; // you can select a moderator only at a specific step

            Utils.setModerator(userjoined.producer_socket_id);
            Utils.emitModerator(userjoined.producer_socket_id);
         
        })
        $( '.videocontainer_'+userjoined.producer_socket_id ).hover(
            function() {
                if (SessionData.currentGameStep != Config.stepForModerator) return; // you can select a moderator only at a specific step
                $( this ).addClass("hover");
            }, function() {
                if (SessionData.currentGameStep != Config.stepForModerator) return; // you can select a moderator only at a specific step
                $( this ).removeClass("hover");
            }
        );

        m.redraw();

    }

    static postCanvasToUsers () {
        let json = SessionData.canvas.toJSON(Config.metadataOnCanvasUpdate); // the meta the canvas should update
        let elem = document.getElementById("currentprogress" + SessionData.currentGameStep);

        let d = {
                json: json,
                activitiesOnBoard: SessionData.activitiesonboard,
                gamestep: SessionData.currentGameStep,
                currenttime: (elem != undefined) ? elem.style.height : "",
                room: SessionData.roomname,
                moderator: SessionData.moderator,
                user_id: SessionData.socket.id,
                user_name: SessionData.username
        }
        console.log ("post canvas to users", json);
            
        SessionData.socket.emit('updatecanvas', d);
        Utils.emitModerator(SessionData.moderator);
    }
    static saveCanvasJSON () {
        let json = SessionData.canvas.toJSON(Config.metadataOnCanvasUpdate);

        let keysToRemove = ["background", "backgroundColor", "angle", "hasControls", "version", "top", "left", "type", "endAngle", "fill", "fillRule", "flipX", "flipY", 
        "globalCompositeOperation", "height", "opacity", "originX", "originY", "paintFirst", "radius", "scaleX", "scaleY", "shadow", "skewX", "skewY", "startAngle", "stroke",
        "strokeDashArray", "strokeDashOffset", "strokeLineCap", "strokeLineJoin", "strokeMiterLimit", "subTargetCheck", "selectable", "strokeUniform", "strokeWidth", "width", "cropX", "cropY", "crossOrigin", 
        "filters", "src", "points", "visible", "charSpacing", "direction", "fontFamily", "fontSize", "fontWeight", "lineHeight", "lineThrough", "minWidth", "overline","path","splitByGrapheme","styles", "textAlign",
        "textBackgroundColor", "underline", "rx", "ry", "x1", "x2", "y1", "y2", "linethrough", "fontStyle", "lockMovementX", "lockMovementY", "hasBorders", "hoverCursor", "evented"];

        

        Utils.removeKeys(json, keysToRemove);
        
        SessionData.canvas.discardActiveObject();
        var sel = new fabric.ActiveSelection(SessionData.canvas.getObjects(), {
            canvas: SessionData.canvas,
        });
        SessionData.canvas.setActiveObject(sel);
        SessionData.canvas.requestRenderAll();
        
        // save complete dataurl of canvas
        var dataUrl = sel.toDataURL("jpeg",{quality:1});
     
        var img = new Image();
        img.crossOrigin = "anonymous";
        img.onload = function () {
            SessionData.canvas.discardActiveObject().renderAll(); // deselect selection if we're in evaluation mode

            SessionData.socket.emit("savegamejson", {
                json: JSON.stringify(json),
                room: SessionData.roomname,
                dataURL: dataUrl
            }) 
            var download = document.createElement('a');
            download.download = 'Spielfeld';
            download.href = dataUrl;
            download.target = '_blank';
            download.click();
        }
        img.src = dataUrl;
    }

    static cardsInGroup (group){
        if (group == undefined) return;
        let atmospheres = 0;
        let activities = 0;
        let rooms = 0;
        
        let initPosition;
        for (var i = 0; i < group._objects.length; i++) {
            let o = group._objects[i];
            if (o.cardtype == Config.cardtypes.atmosphere) atmospheres++;
            if (o.cardtype == Config.cardtypes.room) rooms++;
            if (o.cardtype == Config.cardtypes.activity) {
                if (activities == 0) {
                    var matrix = o.calcTransformMatrix();
                    // 2. choose the point you want, fro example top, left.
                    var point = { x: -o.width/2, y: o.height/2 - o.height };
                    // 3. transform the point
                    var pointOnCanvas = fabric.util.transformPoint(point, matrix)
                    initPosition = {left: pointOnCanvas.x, top:pointOnCanvas.y} // the position of the first activity. We position everything based on that
                }
                activities++;
            }
        }
        let data = {
            atmospheres: atmospheres,
            activities: activities,
            rooms: rooms,
            initPosition: initPosition
        }
        return data;
    }


    static GroupsToString () {
        
        let label = "Aufgabe: " + Database.getGameStepsJSON().steps["step" + SessionData.currentGameStep].title +".";
                           
                            
        for (let i = 0; i < SessionData.objectsOnCanvas.length; i++) {
            let obj = SessionData.objectsOnCanvas[i];
            if (obj.cardtype == Config.cardtypes.island) {

                let islandlabel = "Insel: ";
                let activitieslabel = "Aktivitäten: ";
                let atmosphereslabel = "Atmosphären: ";
                let roomslabel = "Räume: ";
              
                for (let j = 0; j < obj._objects.length; j++) {
                    let c = obj._objects[j];
                   
                    if (c.cardtype == "grouptitle" && c.text != undefined) islandlabel += c.text;

                    if (c.cardtype == Config.cardtypes.activity) activitieslabel += c.title + ", ";
                    if (c.cardtype == Config.cardtypes.atmosphere) atmosphereslabel += c.title +", ";
                    if (c.cardtype == Config.cardtypes.room) roomslabel += c.title + ", ";
                    
                }
                if (islandlabel == "Insel: ") islandlabel = "Insel: kein Name.";
                if (activitieslabel == "Aktivitäten: ") activitieslabel = "keine Aktivitäten.";
                if (atmosphereslabel == "Atmosphären: ") atmosphereslabel = "keine Atmosphären.";
                if (roomslabel == "Räume: ") roomslabel = "keine Räume.";
                label += islandlabel + activitieslabel + atmosphereslabel + roomslabel;
               
            }
        }

        return label;
    }

    static analyseBoard () {   
        $('#gameWrapper .window').addClass("large");
        $('#gameWrapper .window').removeClass("minimized");
        $('#gameWrapper .window .close').css("display", "none");
        let mediasize = 30;
        let sets =[ { sets: ["Medien"], size:mediasize, arialabel: "Medienkreis"} ];
        
        $('#gameWrapper #gamestep-content .title').append(Database.getUIJSON().finshedGameTitle);
        $('#gameWrapper #gamestep-content .description').append("<div id='description'>" + Database.getUIJSON().finishedGameDescription + "</div>");
        $('#gameWrapper #gamestep-content .description').append("<div id='venn'></div>")
        

        for (let i = 0; i < SessionData.objectsOnCanvas.length; i++) {
            let obj = SessionData.objectsOnCanvas[i];
            let islandname;
            if (obj.cardtype == Config.cardtypes.island) {

                let venntitle; 
                let vennsize = 0; 
                let _loudness;
                let _access; 
                let _media = 0;
                let analysis = {
                    ausleihe: 0,
                    information: 0,
                    gastronomie: 0,
                    rueckzugsbereich: 0,
                    lesebereich: 0,
                    werkstatt: 0,
                    versammlungsort:0
                };
                for (let j = 0; j < obj._objects.length; j++) {
                    let c = obj._objects[j];
                   
                    if (c.cardtype == Config.cardtypes.activity) vennsize++;
                    
                    if (c.cardtype == Config.cardtypes.safecircle) _access = 0; // geschützt
                    if (c.cardtype == Config.cardtypes.opencircle) _access = 1; // durchlässig
                    if (c.cardtype == "grouptitle") islandname = c.text;
                    if (c.cardtype == Config.cardtypes.opencircle || c.cardtype == Config.cardtypes.safecircle) {
                        if (c.fill == "rgba(255,0,0,0.3)") _loudness = 1;
                        if (c.fill != "rgba(255,0,0,0.3)") _loudness = 0;   
                    }
   
                    if (c.analysis != undefined && c.analysis != "") {
                        if (c.cardtype != Config.cardtypes.room) _media += (c.analysis.medien == undefined) ? 0 : c.analysis.medien;
                        
                        analysis.ausleihe += (c.analysis.ausleihe == undefined) ? 0 : c.analysis.ausleihe;
                        analysis.information += (c.analysis.information == undefined) ? 0 : c.analysis.information;
                        analysis.gastronomie += (c.analysis.gastronomie == undefined) ? 0 : c.analysis.gastronomie;
                        analysis.rueckzugsbereich += (c.analysis.rueckzugsbereich == undefined) ? 0 : c.analysis.rueckzugsbereich;
                        analysis.lesebereich += (c.analysis.lesebereich == undefined) ? 0 : c.analysis.lesebereich;
                        analysis.werkstatt += (c.analysis.werkstatt == undefined) ? 0 : c.analysis.werkstatt;
                        analysis.versammlungsort += (c.analysis.versammlungsort == undefined) ? 0 : c.analysis.versammlungsort;

                        
                    }
                }
                var max = Math.max.apply(null,Object.keys(analysis).map(function(x){ return analysis[x] }));
                venntitle = Object.keys(analysis).filter(function(x){ return analysis[x] == max; })[0]

               // if (islandname != "" && islandname != undefined) venntitle += "(" + islandname + ")";
                
                
               
                // overlap is calculated:
                /*
                    mediacirclesize
                    vennsize: how many activities we have in a circle
                    _media: how many media we have in the circle
                    mediasize: how big the media circle is
                */
                // sum of media in island / 0,anzahlderaktivitäten

                let _m = _media / (0.1*vennsize); // size of media circle is 30. Overlap is relative to that
                
                _m = _m || 0; // if _m is NaN make it 0
                let m_perc = (vennsize*_m/100);
                // console.log (venntitle, "size: " + vennsize, "media in circle: " + _media);
                // console.log ("total activities on board", allactivities);
                // console.log ("total media on board", allmedia);
                console.log (islandname, "media: " + _media, "overlap related to circle size: "+m_perc, "overlap size: " + _m);
                
                sets.push( {sets: [venntitle+"_"+obj.id, "Medien"], arialabel:venntitle.charAt(0).toUpperCase() + venntitle.slice(1), size: m_perc, islandname:islandname}); 
                sets.push ( { sets: [venntitle+"_"+obj.id], size:vennsize, arialabel:venntitle.charAt(0).toUpperCase() + venntitle.slice(1), label:venntitle.charAt(0).toUpperCase() + venntitle.slice(1), loudness:_loudness, access:_access, islandname:islandname} )

            }
        }
        
        
        console.log ("SETS", sets);
        
        var chart = venn.VennDiagram();
        
        let div = d3.select("#venn");
        div.datum(sets).call(chart);
        
        

        // add a tooltip
        var tooltip = d3.select("body").append("div")
        .attr("class", "venntooltip");

        // add listeners to all the groups to display tooltip on mouseover
        div.selectAll("g")
        .on("mouseover", function(d, i) {
            // sort all the areas relative to the current item
            venn.sortAreas(div, d);
            // Display a tooltip with the current size
            if (d.sets[0] != "Medien") tooltip.transition().duration(400).style("opacity", 1);
            if (d.islandname != undefined) tooltip.text(d.islandname);
            if (d.islandname == undefined) tooltip.text("");
            
        })

        .on("mousemove", function() {
            tooltip.style("left", (d3.event.pageX) + "px")
                .style("top", (d3.event.pageY - 28) + "px");
        })

        .on("mouseout", function(d, i) {
            tooltip.transition().duration(400).style("opacity", 0);
      
        });
    
    }




}

export default Utils;