import m from "mithril";
import $ from 'jquery';

let cards = {};
let cardsCount = 0;
let cardsTotal; // how many json database of card stacks do we have (activities, atmospheres, rooms)
let gamesteps = {};
let UI = {};
let mainTools = {}; // the main toolbar
let groupTools = {};// the group toolbar
let cardsLoaded = new Event("cardsLoaded");
let gamestepsLoaded = new Event("gamestepsLoaded");
let DBloadComplete = new Event("DBloadComplete"); // cards and gamesteps loaded
let UIDBLoaded = new Event("UIDBLoaded"); // just interface loaded
let ToolsDBLoaded = new Event("ToolsDBLoaded"); // just tools loaded
let toolsTotal; 
let toolsCount = 0;

class Database {


    // loads the complete database. First the cards, then the gamesteps.
    static loadDatabase() {
        this.loadCardsJSON();
    }

    static getCardsJSON() {
        return cards;
    }
    static getGameStepsJSON() {
        return gamesteps;
    }

    static getMainToolsJSON() {
        return mainTools;
    }
    static getGroupToolsJSON() {
        return groupTools;
    }
    static getUIJSON() {
        return UI;
    }

    static loadToolsJSON () {
        toolsTotal = 2;
        this.loadTools("../db/maintools.json");
        this.loadTools("../db/grouptools.json");
    }

    static loadUIJSON() {
        this.loadUI("../db/interface.json");
    }

    static loadCardsJSON() {
        cardsTotal = 3;
        this.loadCardStack("../db/activities.json");
        
    }
    static loadGameStepsJSON() {
        this.loadGameStepsStack("../db/gamesteps.json");
        if (m.route.param().version == 2) $('body').addClass("version2");
    }


    static loadUI(stack) {
        m.request({
                method: "GET",
                url: stack,
            })
            .then(function (result) {
                UI = {
                    ...UI,
                    ...result
                };
                document.dispatchEvent(UIDBLoaded); // let the gameloop know that the UI is loaded
            })
    }

    static loadTools(stack) {
        m.request({
            method: "GET",
            url: stack,
        })
        .then( (result) => {
            toolsCount += 1;
            if (stack.includes("group")) {
                groupTools = {
                    ...groupTools,
                    ...result
                };
            } else {
                mainTools = {
                    ...mainTools,
                    ...result
                };
            }
            
            if (toolsCount >= toolsTotal) {
                document.dispatchEvent(ToolsDBLoaded); // let the gameloop know that all mainTools are loaded
                document.dispatchEvent(DBloadComplete); // let the gameloop know that the database is completely loaded
            }
             
        })
    }
    static loadCardStack(stack) {
        m.request({
                method: "GET",
                url: stack,
            })
            .then((result) => {
                cardsCount += 1;
                cards = {
                    ...cards,
                    ...result
                };
                if (cardsCount == 1) this.loadCardStack("../db/atmospheres.json");
                if (cardsCount == 2) this.loadCardStack("../db/rooms.json");
                if (cardsCount >= cardsTotal) {
                    document.dispatchEvent(cardsLoaded); // let the gameloop know that all cards are loaded
                    this.loadGameStepsJSON();
                }
            })
    }



    static loadGameStepsStack(stack) {
        m.request({
                method: "GET",
                url: stack,
            })
            .then(function (result) {

                gamesteps = {
                    ...gamesteps,
                    ...result
                };
                document.dispatchEvent(gamestepsLoaded); // let the gameloop know that all gamesteps are loaded
                Database.loadToolsJSON();
                


            })
    }



}

export default Database;