
//import Config from "../config";

export const SessionData = {
    mousePressed: false, // set mouse pressed status for detaching objects
    detachInterval: "",
    detachTimeout: "",
    detachCounter: 0,

    evaluation: false, // if the user is doing an evaluation, interaction should be different
    evaluationTargetIsland: true, // when clicking during the evaluation, should the target be an island or its children
    username: "", // the visible username of the user
    socketID: "", // the client socket ID
    totalUsers: 0, // the total amount of users connected in the room
    roomClient: null, // the media soup room client
    users: [], // the usernames
    roomname: "", // the roomname
    socket:null, // the socket of the connection
    canvas:"", // the board canvas
    totalDuration: 0, // the totalDuration of the game
    timeJoker: 0, // the current timejoker
    allTimeJokers: 0, // the total duration of the timejokers activated. needed for the progressbar
    activitiesiadded:0, // count how many activities a user can add
    activitiesonboard: 0, // count the total activities on the board
    objectsOnCanvas: [], // all fabric objects on canvas 
    readyUsers:0, // the total number of users ready to proceed
    moderator: "", // the moderator of the current session
    connected: false, // note if this is the first time we connect or a reconnect
    currentGameStep:0, // the progress of the user in the game
    errorcheck: {
        browser: false,
        socket: false,
        video: false,
        audio: false,
        device: false
    }

}


export default SessionData;
