import m from 'mithril';
import SessionData from '../models/session.class';
import $ from 'jquery';
import Database from '../models/database.class';
let pausedvideo = false; // did we pause the stream?
let pausedaudio = false; // did we pause the stream?
class videoButtons {

    view(vnode) {

      let audioX = vnode.attrs.audioX;
      let audioY = (vnode.attrs.audioY == undefined) ? 0 : vnode.attrs.audioY;
      let videoX = vnode.attrs.videoX;
      let videoY = (vnode.attrs.videoY == undefined) ? 0 : vnode.attrs.videoY;
        return [
            // VIDEO CONTROLS
            m("div", { 
              class: "buttons" //(showVideoControls == true) ? "buttons" : "buttons hide"
            }, [
  
              // Audio button
              m("a", {
                class: "button audio", tabindex:1, "aria-label":Database.getUIJSON().myaudiooff, tooltip: Database.getUIJSON().myaudiooff, distX:audioX, distY:audioY,
                href:"#",
                onclick: (e) => { 
                  e.preventDefault();
                  $('.audio').toggleClass("slash");
                  if (!SessionData.roomClient.pauseAudio) {
                    SessionData.roomClient.pauseProducer(SessionData.roomClient.mediaType.audio);
                    $('.audio').addClass("slash"); 
                    $('.fa-microphone').addClass('fa-microphone-slash');
                    $('.fa-microphone').removeClass('fa-microphone');
                    $('a.button.audio').attr("tooltip", Database.getUIJSON().myaudioon);
                    $('a.button.audio').attr("aria-label", Database.getUIJSON().myaudioon);
  
                  } else {
                    SessionData.roomClient.resumeProducer(SessionData.roomClient.mediaType.audio);
                    $('.audio').removeClass("slash"); 

                    $('.fa-microphone-slash').addClass('fa-microphone');
                    $('.fa-microphone-slash').removeClass('fa-microphone-slash');
                    $('a.button.audio').attr("tooltip", Database.getUIJSON().myaudiooff);
                    $('a.button.audio').attr("aria-label", Database.getUIJSON().myaudiooff);
  
                  }
                }
              }, [
                m("span", {class: "icon"}, [
                  m("i", {class: "fas fa-microphone"})
                ]),
              ]),
  
  
              // video button
              m("a", {
                class: "button video", tabindex:2, "aria-label": Database.getUIJSON().myvideooff, tooltip: Database.getUIJSON().myvideooff, distX:videoX, distY:videoY,
                href:"#",
                onclick: (e) => { 
                  e.preventDefault();
                  if (!SessionData.roomClient.pauseVideo) {
                    SessionData.roomClient.pauseProducer(SessionData.roomClient.mediaType.video);
                    $('.video').addClass("slash"); 
                    $('.fa-video').addClass('fa-video-slash');
                    $('.fa-video').removeClass('fa-video');
                    $('a.button.video').attr("tooltip", Database.getUIJSON().myvideoon);
                    $('a.button.video').attr("aria-label", Database.getUIJSON().myvideoon);
                  } else {
                    $('.video').removeClass("slash"); 
                    SessionData.roomClient.resumeProducer(SessionData.roomClient.mediaType.video);

                    $('.fa-video-slash').addClass('fa-video');
                    $('.fa-video-slash').removeClass('fa-video-slash');
                    $('a.button.video').attr("tooltip", Database.getUIJSON().myvideooff);
                    $('a.button.video').attr("aria-label", Database.getUIJSON().myvideooff);
  
                  }
                }
              }, [
                m("span", {class: "icon"}, [
                  m("i", {class: "fas fa-video"})
                ])
              ])
  
            ]), // end buttons
        ]

    }

}
export default videoButtons;